
import React, { useState, useEffect } from 'react';

import {
    List,
    ListItem,
    IconButton,
    ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


export default function GuiasFormAddGroup(props) {
    const {
            listItems,
            setListItems,
            
        } = props;
    const removeItem = (value)=>{
        let items = listItems
        items = items.filter((item)=>item!==value)
        setListItems(items);
    }
    const [itemsToShow, setItemsToShow]=useState([])
    useEffect(()=>{
        setItemsToShow(listItems.slice(listItems.length-10).reverse())
    },[listItems])
    return(
        <List sx={{ 
            width: '100%', 
            bgcolor: 'background.paper' ,
            position: 'relative',
            overflow: 'auto',
            height: "100%",
            }}>
            Ultimas 10
            {itemsToShow.map((value) => (
                <ListItem
                key={value}
                disableGutters
                secondaryAction={
                    <IconButton onClick={()=>removeItem(value)}>
                        <DeleteIcon />
                    </IconButton>
                }
                >
                <ListItemText primary={`${value}`} />
                </ListItem>
            ))}
        </List>
    )
}