/* Importaciones */

// De React
import React, { useState , useEffect } from "react";

// De MUI Material
import { Container, Modal, Typography, Grid, Button, Paper, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// Objeto para la creación de los domicilios
export const objetoDireccion = require("./objetoDireccion.json");

function FormularioDirecciones(props) {

    // Propiedades del componente
    const {open, handleClose, id} = props;

    // Use states de los campos
    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [telefono1, setTelefono1] = useState('');
    const [telefono2, setTelefono2] = useState('');
    const [calle, setCalle] = useState('');
    const [numeroExterior, setNumeroExterior] = useState('');
    const [numeroInterior, setNumeroInterior] = useState('');
    const [colonia, setColonia] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [referencia, setReferencia] = useState('');
    const [coordenadaLatitud, setCoordenadaLatitud] = useState('');
    const [coordenadaLongitud, setCoordenadaLongitud] = useState('');
    const [cargando, setCargando] = useState(false);
    
    // Estados de error
    const [mensajeDeError, setMensajeDeError] = useState('');
    const [tituloDeError, setTituloDeError] = useState('');
    const [error, setError] = useState(false);

    // Objeto de estilos para agregar scroll al formulario
    const styles = {
        marginY: 5, 
        paddingX: 5, 
        paddingY: 5,
        position: 'absolute',
        transform: 'translate(-50%,-5,0%)',
        overflowY: 'scroll',
        width: '80%',
        height: '89%',
        border: '2px solid #efeff0',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3
    };

    // Catch de los campos
    const catchNombre = (event) => setNombre(event.target.value);
    const catchApellidoPaterno = (event) => setApellidoPaterno(event.target.value);
    const catchApellidoMaterno = (event) => setApellidoMaterno(event.target.value);
    const catchTelefono1 = (event) => setTelefono1(event.target.value);
    const catchTelefono2 = (event) => setTelefono2(event.target.value);
    const catchCalle = (event) => setCalle(event.target.value);
    const catchNumeroExterior = (event) => setNumeroExterior(event.target.value);
    const catchNumeroInterior = (event) => setNumeroInterior(event.target.value);
    const catchColonia = (event) => setColonia(event.target.value);
    const catchMunicipio = (event) => setMunicipio(event.target.value);
    const catchEstado = (event) => setEstado(event.target.value);
    const catchPais = (event) => setPais(event.target.value);
    const catchCodigoPostal = (event) => setCodigoPostal(event.target.value);
    const catchEmpresa = (event) => setEmpresa(event.target.value);
    const catchReferencia = (event) => setReferencia(event.target.value);
    const catchCoordenadaLatitud = (event) => setCoordenadaLatitud(event.target.value);
    const catchCoordenadaLongitud = (event) => setCoordenadaLongitud(event.target.value);

    /* Conexión con la API */ 

    //Objeto para la configuracion de enviroment
    const envConfig = {
        url : (process.env.REACT_APP_ENVIROMENT === "prod") ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    };

    // Recuperar el identificador del usuario activo
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    // Guardar dirección
    const crearDireccion = async () => {
        const url = envConfig.url + '/api/v1/adminDirecciones/store';

        if(!crearObjetoDomicilio()) {
            return;
        }

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify( objetoDireccion )
        }).then((respuesta) => {
            if(respuesta.ok) {
                cerrarModal();
            } else {
                if(respuesta.status === 400){
                    setError(true);
                    setTituloDeError('Ocurrió un problema');
                    setMensajeDeError('El servidor tuvo un problema, intentalo nuevamente.');
                }

                if(respuesta.status === 404){
                    setError(true);
                    setTituloDeError('Ocurrió un problema');
                    setMensajeDeError('Ingreso un Codigo Postal invalido, reviselo e intentelo nuevamente');
                }
            }       
        }).catch(
            (error) => {
                setError(true);
                setTituloDeError('Ocurrió un problema');
                setMensajeDeError('Tenemos problemas para comunicarnos con el servidor, intentalo nuevamente más tarde, si el problema continúa, contacta con soporte técnico.');
            }
        );

        setCargando(false);
    };

    // Actulizar dirección
    const actualizarDireccion = async () => {
        const url = envConfig.url + '/api/v1/adminDirecciones/update';

        if(!crearObjetoDomicilio()) {
            return;
        }

        objetoDireccion.data.id = id;

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify( objetoDireccion )
        }).then((respuesta) => {
            if(respuesta.ok) {
                cerrarModal();
            } else {
                if(respuesta.status === 400){
                    setError(true);
                    setTituloDeError('Ocurrió un problema');
                    setMensajeDeError('El servidor tuvo un problema, intentalo nuevamente.');
                }

                if(respuesta.status === 404){
                    setError(true);
                    setTituloDeError('Ocurrió un problema');
                    setMensajeDeError('Ingreso un Codigo Postal invalido, reviselo e intentelo nuevamente');
                }
            }

        }).catch(
            (error) => {
                setError(true);
                setTituloDeError('Ocurrió un problema');
                setMensajeDeError('Tenemos problemas para comunicarnos con el servidor, intentalo nuevamente más tarde, si el problema continúa, contacta con soporte técnico.');
            }
        );

        setCargando(false);
    };

    // Obtener dirección 
    async function obtenerDireccion(id) {
        const url = envConfig.url + '/api/v1/adminDirecciones/get_item';

        const peticion = {
            data: {
                user_id: userData.id,
                id: id
            }
        }

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(peticion)
        }).then( (respuesta) => {
            if(!respuesta) {
                setError(true);
                setTituloDeError('Ocurrió un problema');
                setMensajeDeError('El servidor tuvo un problema, intentalo nuevamente.');
            } else {
                return respuesta.json();
            }
        }).then( data => {
            // Muestra los datos en las entradas
            setNombre(data.data.data.nombre);
            setApellidoPaterno(data.data.data.apellidoPaterno);
            setApellidoMaterno(data.data.data.apellidoMaterno);
            setTelefono1(data.data.data.telefono1);
            setTelefono2(data.data.data.telefono2);
            setCalle(data.data.data.calle);
            setNumeroExterior(data.data.data.numeroExterior);
            setNumeroInterior(data.data.data.numeroInterior);
            setColonia(data.data.data.colonia);
            setMunicipio(data.data.data.municipio);
            setEstado(data.data.data.estado);
            setPais(data.data.data.pais);
            setCodigoPostal(data.data.data.codigoPostal);
            setEmpresa(data.data.data.empresa);
            setReferencia(data.data.data.referencia);
            setCoordenadaLatitud(data.data.data.coordenadaLatitud);
            setCoordenadaLongitud(data.data.data.coordenadaLongitud);

            // Oculta la barra de progreso
            setCargando(false);
        } ).catch(error => {
            setCargando(false);
            setError(true);
            setTituloDeError('Ocurrió un problema');
            setMensajeDeError('Tenemos problemas para comunicarnos con el servidor, intentalo nuevamente más tarde, si el problema continúa, contacta con soporte técnico.');
        });
    }


    /* Función para guardar los valores en un objeto JSON */

    // Crear objeto domicilio
    function crearObjetoDomicilio() {

        setCargando(true);
        
        // Se validan los datos
        let faltanDatos = false;
        let faltantes = [];

        if(nombre===''){
            faltanDatos = true;
            faltantes.push(' Nombre(s)');
        }

        if(apellidoPaterno===''){
            faltanDatos = true;
            faltantes.push(' Apellido Paterno');
        }

        if(telefono1===''){
            faltanDatos = true;
            faltantes.push(' Teléfono principal');
        }

        if(calle==='') {
            faltanDatos = true;
            faltantes.push(' calle');
        }

        if(numeroExterior==='') {
            faltanDatos = true;
            faltantes.push(' número exterior');
        }

        if(colonia==='') {
            faltanDatos = true;
            faltantes.push(' colonia');
        }

        if(municipio==='') {
            faltanDatos = true;
            faltantes.push(' municipio');
        }

        if(estado==='') {
            faltanDatos = true;
            faltantes.push(' estado');
        }

        if(pais==='') {
            faltanDatos = true;
            faltantes.push(' país');
        }

        if(codigoPostal==='') {
            faltanDatos = true;
            faltantes.push(' código postal');
        }

        if(coordenadaLatitud==='') {
            faltanDatos = true;
            faltantes.push(' coordenadas en latitud');
        }

        if(coordenadaLongitud==='') {
            faltanDatos = true;
            faltantes.push(' coordenadas en longitud');
        }
        
        if(faltanDatos) {
            setError(true);
            setTituloDeError(`Faltan datos`);
            setMensajeDeError(`Por favor, revisa que los siguientes datos estén correctos e intental nuevamente: ${faltantes.toString()}.`);
            setCargando(false);
            return false;
        } else {
            objetoDireccion.data.nombre = nombre;
            objetoDireccion.data.apellidoPaterno = apellidoPaterno;
            objetoDireccion.data.apellidoMaterno = apellidoMaterno;
            objetoDireccion.data.telefono1 = telefono1;
            objetoDireccion.data.telefono2 = telefono2;
            objetoDireccion.data.user_id = userData.id;
            objetoDireccion.data.calle = calle;
            objetoDireccion.data.numeroExterior = numeroExterior;
            objetoDireccion.data.numeroInterior = numeroInterior;
            objetoDireccion.data.colonia = colonia;
            objetoDireccion.data.municipio = municipio;
            objetoDireccion.data.estado = estado;
            objetoDireccion.data.pais = pais;
            objetoDireccion.data.codigoPostal = codigoPostal;
            objetoDireccion.data.empresa = empresa;
            objetoDireccion.data.referencia = referencia;
            objetoDireccion.data.coordenadaLatitud = coordenadaLatitud;
            objetoDireccion.data.coordenadaLongitud = coordenadaLongitud;
            setError(false);
            return true;
        }
    }


    /* Funciones varias */

    // Cambio de estado para ocultar el modal
    const cerrarModal = () => {
        setError(false);
        setCargando(false);
        limpiarDatos();
        handleClose();
    }

    // Limpiar los campos
    const limpiarDatos = () => {
        setNombre('');
        setApellidoPaterno('');
        setApellidoMaterno('');
        setTelefono1('');
        setTelefono2('');
        setCalle('');
        setNumeroExterior('');
        setNumeroInterior('');
        setColonia('');
        setMunicipio('');
        setEstado('');
        setPais('');
        setCodigoPostal('');
        setEmpresa('');
        setReferencia('');
        setCoordenadaLatitud('');
        setCoordenadaLongitud('');
    }


    /* Funciones para recuperrar datos en caso de que exista un id */
    useEffect( () => {
        if(id) {
            setCargando(true);
            obtenerDireccion(id);
        } else {
            limpiarDatos();
        }
    },[id]);
    

    return (
        <Modal hideBackdrop open = { open } onClose = { handleClose } aria-labelledby="parent-modal-title">

            <Container maxWidth="lg">
                <Paper elevation={10} sx={ styles } component="form">

                    {/* Encabezado */}
                    <Typography variant='h5' sx={{marginy: 2}}>
                        {
                            id ? (
                                `Actualiza la siguiente dirección`
                            ) : (
                                `Agrega una dirección nueva`
                            )
                        }
                        {
                            cargando ? (
                                <CircularProgress disableShrink size={20} sx={{marginX: 1}}/>
                            ) : (
                                ``
                            )
                        }
                    </Typography>

                    {/* Formulario de direcciones */}
                    <Grid container spacing={2} sx={{marginY: 2}}>
                        
                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputNombre" 
                                onChange={catchNombre} 
                                value={nombre} 
                                required={true} 
                                label="Nombre(s)" 
                                placeholder="Luz Maria" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputApellidoPaterno" 
                                onChange={catchApellidoPaterno} 
                                value={apellidoPaterno} 
                                required={true} 
                                label="Apellido Paterno" 
                                placeholder="Hernandez" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputApellidoMaterno" 
                                onChange={catchApellidoMaterno} 
                                value={apellidoMaterno} 
                                label="Apellido Materno(Opcional)" 
                                placeholder="Gomez" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="inputTelefono1" 
                                onChange={catchTelefono1} 
                                value={telefono1} 
                                required={true} 
                                label="Teléfono principal" 
                                placeholder="##########" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "10"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="inputTelefono2" 
                                onChange={catchTelefono2} 
                                value={telefono2} 
                                label="Teléfono alternativo(Opcional)" 
                                placeholder="##########" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "10"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="inputCalle" 
                                onChange={catchCalle} 
                                value={calle} 
                                required={true} 
                                label="Calle" 
                                placeholder="Independencia" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField 
                                id="inputNumeroExterior" 
                                onChange={catchNumeroExterior} 
                                value={numeroExterior} 
                                required={true} 
                                label="Número exterior" 
                                placeholder="413" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "10"}}
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField 
                                id="inputNumeroInterior" 
                                onChange={catchNumeroInterior} 
                                value={numeroInterior} 
                                label="Número interior(Opcional)" 
                                placeholder="15" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "10"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputColonia" 
                                onChange={catchColonia} 
                                value={colonia} 
                                required={true} 
                                label="Colonia" 
                                placeholder="Providencia" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputMunicipio" 
                                onChange={catchMunicipio} 
                                value={municipio} 
                                required={true} 
                                label="Municipio" 
                                placeholder="Guadalajara" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputEstado" 
                                onChange={catchEstado} 
                                value={estado} 
                                required={true} 
                                label="Estado" 
                                placeholder="Jalisco" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputPais" 
                                onChange={catchPais} 
                                value={pais} 
                                required={true} 
                                label="País" 
                                placeholder="México" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputCodigoPostal" 
                                onChange={catchCodigoPostal} 
                                value={codigoPostal} 
                                required={true} 
                                label="Código postal" 
                                placeholder="45620" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "5"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField 
                                id="inputEmpresa" 
                                onChange={catchEmpresa} 
                                value={empresa} 
                                label="Empresa(Opcional)" 
                                placeholder="" 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField 
                                multiline 
                                maxRows={6} 
                                onChange={catchReferencia} 
                                value={referencia} 
                                id="inputReferencia" 
                                label="Referencia(Opcional)" 
                                placeholder="En el domicilio se encuentra..." 
                                fullWidth={true} 
                                margin="none"
                                inputProps={{"maxlength" : "100"}}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="inputCoordenadaLatitud" 
                                onChange={catchCoordenadaLatitud} 
                                value={coordenadaLatitud} 
                                required={true} 
                                label="Coordenada de latitud" 
                                placeholder="-150.251463" 
                                fullWidth={true} 
                                margin="none" 
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="inputCoordenadaLongitud"
                                onChange={catchCoordenadaLongitud} 
                                value={coordenadaLongitud} 
                                required={true} 
                                label="Coordenada de longitud" 
                                placeholder="30.978645" 
                                fullWidth={true} 
                                margin="none" 
                            />
                        </Grid>
                    </Grid>


                    {/* Botones */}

                    <Grid container justifyContent="flex-end" alignItems="flex-start" spacing={2} sx={{marginY: 2}}>
                        {
                            error ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>{tituloDeError}</AlertTitle>
                                        {mensajeDeError}
                                    </Alert>
                                </Grid>
                            ) : (
                                ``
                            )
                        }

                        <Grid item>
                            <Button variant="outlined" onClick={ cerrarModal }>Cancelar</Button>
                        </Grid>

                        <Grid item>
                            {
                                id ? (
                                <Button variant="contained" onClick={ actualizarDireccion } disabled={cargando}>Actualizar</Button>
                                ) : (
                                <Button variant="contained" onClick={ crearDireccion } disabled={cargando}>Agregar</Button>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Container sx={ {marginY: 2, textAlign: "right"} }>

                        
                        
                        
                    </Container>
                </Paper>
            </Container>

        </Modal>
    );
}

export default FormularioDirecciones;