
import React, {useEffect, useState} from 'react';
import {Howl, Howler} from 'howler';
import CoinsSound from "../../../../sounds/coins.mp3"
import BuzzerSound from "../../../../sounds/buzzer.mp3"
import {
    FormControl, 
    FormGroup, 
    Button, 
    Input,
    InputLabel
} from '@mui/material';

import FormHelperText from '@mui/material/FormHelperText';

export default function GuiasFormAdd(props) {
    const {
            listItems,
            setListItems,
            waybills,
            setWaybills,
            dataToSend
        } = props;
    const [formValue, setFormValue] = useState("");
    const sound = Howler;
    sound.volume(1);

    const succesSound = new Howl({
        src:CoinsSound,
        preload:false,
        html5:true
    })
    const errorSound = new Howl({
        src:BuzzerSound,
        preload:false,
        html5:true
    })

    const playSuccess = async ()=>{
        succesSound.play();
        return;
    }
    const playError = async ()=> {
        errorSound.play();
        return;
    }
    const validWaybill = (waybill)=> 
                (
                    waybill.length>=10 &&
                    waybill.length<=30 &&
                    waybill!==""&&
                    waybill.search(' ', '\t', '\n')===-1&&
                    listItems.filter((value)=>(value===waybill)).length === 0
                );


    const handleChange = (e) =>{
        setFormValue(e.target.value);
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        
        if (validWaybill(formValue)) {
            await setListItems([...listItems,formValue]);
            setFormValue("");
            playSuccess();
            setWaybills(waybills+1)
        }
        else{
            playError();
            setWaybills(waybills+1);
            setTimeout(function (){
                alert("Guia ´"+formValue+"´ no valida o repetida")
            },1000);
            setFormValue("");
        }
    }
    
    return(
        <form action="#" onSubmit={handleSubmit}>
            <FormGroup>
                <FormControl>
                    <InputLabel htmlFor="my-input">Guia Llegada</InputLabel>
                    <Input 
                        id="guia"
                        inputProps={{ maxLength: 30 }}
                        aria-describedby="my-helper-text" 
                        autoFocus
                        onChange={handleChange} 
                        value={formValue}/>
                    <FormHelperText id="my-helper-text">Escanea guia de llegada</FormHelperText>
                </FormControl>
            </FormGroup>
            <Button variant="contained" type="submit">Agregar</Button>

        </form>
    )
}