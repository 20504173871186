import React from 'react';
import {
    Dialog,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';


export default function TrackingItem(props) {
    const { onClose, selectedValue, open, title, items } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };
    
    return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" sx={{heigth:500}}>
        <DialogTitle>{title}</DialogTitle>
        <TableContainer component={Paper}>
            <Table sx={{ width: 800 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Estatus</TableCell>
                    <TableCell align="right">Descripcion</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Hora</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {items.map((item,id) => (
                    (item.length===0)?"":
                    <TableRow
                        key={id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">{item.proceso.name}</TableCell>
                        <TableCell align="right">{item.descripcion}</TableCell>
                        <TableCell align="right">{(new Date(item.created_at)).toLocaleDateString()}</TableCell>
                        <TableCell align="right">{(new Date(item.created_at)).toLocaleTimeString()}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
    </Dialog>
    );
}
