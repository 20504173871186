import './App.css';
import NavHeader from './NavHeader/Dashboard';

import LogInIndex from './Modules/LogIn/index';

import GuiasIndex from './Modules/GuiasAdmin/ReceiveGuias/index';
import FormTracking from './Modules/GuiasAdmin/Tracking/index';
import ReportsIndex from './Modules/GuiasAdmin/Reports/index';
import ShippingIndex from './Modules/GuiasAdmin/Shipping/index';
import ShippingInfoList from './Modules/GuiasAdmin/Shipping/shippingInfoList';
import ShippingInsurance from './Modules/GuiasAdmin/Shipping/shippingInsurance';
import MethodsOfPayment from './Modules/GuiasAdmin/Shipping/methodsOfPayment';
import OriginOrDestination from './Modules/GuiasAdmin/Shipping/originOrDestination';
import PaymentConfirmation from './Modules/GuiasAdmin/Shipping/paymentConfirmation';
import WaybillsTable from './Modules/GuiasAdmin/Shipping/waybillsTable';
import AdminAddress from "./Modules/AdminDirecciones/Index";
import DownloadAndUploadTemplate from './Modules/GuiasAdmin/Shipping/downloadAndUploadTemplate';
import FormularioDeCotizaciones from './Modules/Cotizaciones/FormularioDeCotizaciones';
import ListarCotizaciones from './Modules/Cotizaciones/ListarCotizaciones';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

function App() {
    const userPermissions = JSON.parse( sessionStorage.getItem( 'permissions' ) );
    const routes = [
        ( userPermissions !== null && userPermissions["reports-access"] !== undefined ) ?
            {
                url:"/",
                module:<GuiasIndex locationId={1} location=""/>,
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/waybillsTable",
                module:<WaybillsTable />
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/massiveWaybills",
                module:<DownloadAndUploadTemplate />
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/shipping",
                module:<ShippingIndex/>
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/shippingInfoList",
                module:<ShippingInfoList />
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/shippingInsurance",
                module:<ShippingInsurance />
            } : null,
        // ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
        //     {
        //         url:"/methodsOfPayment",
        //         module:<MethodsOfPayment />
        //     } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/originOrDestination",
                module:<OriginOrDestination />
            } : null,
        ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ? 
            {
                url:"/paymentConfirmation",
                module:<PaymentConfirmation />
            } : null,
        ( userPermissions !== null && userPermissions["sucursal-access"] !== undefined ) ?
            {
                url:"/addWaybills",
                module:<GuiasIndex/>,
            } : null, 
        ( userPermissions !== null && userPermissions["tracking-access"] !== undefined ) ?
            {
                url:"/tracking",
                module:<FormTracking/>,
            } : null,
        ( userPermissions !== null && userPermissions["reports-access"] !== undefined ) ?
            {
                url:"/reports",
                module:<ReportsIndex/>,
            } : null,
        ( userPermissions !== null && userPermissions["address-access"] !== undefined ) ? 
            {
                url:"/adminAddress",
                module:<AdminAddress />
            } : null,
        ( userPermissions !== null && userPermissions["quotes-access"] !== undefined ) ?
            {
                url:"/quotes",
                module: <FormularioDeCotizaciones/>
            } : null,
        ( userPermissions !== null && userPermissions["quotes-access"] !== undefined ) ?
            {
                url:"/listQuotes",
                module: <ListarCotizaciones/>
            } : null
    ];

    // let access = false;

    const modules = routes.map(
        (item,key)=>( item !== null ) ? <Route key={key} exact path={item.url} element={item.module} /> : <></>
    );

    const getFirstModule = () => {
        const userModules = [];
        routes.forEach(
            item => { 
                if( item !== undefined ){
                    if( item !== null ){
                        userModules.push( item );
                        return;
                    }
                } 
            }  
        );
        
        return [ userModules[0].url , userModules[0].module ];
    }

    return (
        <div className = "App">
            <Router>
                {/* <Routes> */}
            {/* <Router> */}
                {/* <NavHeader>
                    <Routes>
                        <Route exact path="/dashboard" element={<div>Sitio aun en construccion Esperalo pronto</div>} />
                        {modules}
                        <Route path="*" element={<Navigate replace to="/dashboard" />} />
                    </Routes>
                </NavHeader> */}
                {/* <Routes>
                    <Route exact path = "/" element = { <LogInIndex /> } />
                </Routes> */}
            {/* </Router> */}
                    {/* <Route 
                        exact 
                        path = "/" 
                        element = { 
                            ( sessionStorage.getItem( 'token' ) !== null ) ?
                                <LogInIndex />
                            : 
                                <NavHeader>
                                    <Routes>
                                        <Route exact path="/dashboard" element={<div>Sitio aun en construccion Esperalo pronto</div>} />
                                        {modules}
                                        <Route path="*" element={<Navigate replace to="/dashboard" />} />
                                    </Routes>
                                </NavHeader>
                        } 
                    /> */}

                {/* </Routes> */}
                
                {
                    ( 
                        sessionStorage.getItem( 'token' ) !== null &&
                        sessionStorage.getItem( 'user' ) !== null &&
                        sessionStorage.getItem( 'permissions' ) !== null
                    ) ?
                        <NavHeader>
                            <Routes>
                                <Route exact path={ getFirstModule()[0] } element={ ( userPermissions !== null && userPermissions["reports-access"] !== undefined ) ? <div>Sitio aun en construccion Esperalo pronto</div> : getFirstModule()[1] } />
                                {modules}
                                <Route path="*" element={<Navigate replace to={ getFirstModule()[0] } />} />
                            </Routes>
                        </NavHeader>
                    :
                        <Routes>
                            <Route exact path = "/" element = { <LogInIndex /> }/>
                            <Route path = "*" element = { <Navigate replace to = "/"/> } />
                        </Routes>
                }
            </Router>
        </div>
    );
}

export default App;
