import React, { Component, useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton ,Container, Paper, Typography, Grid, InputLabel, Select, MenuItem, FormControl, TextField, InputAdornment, Button, Alert, AlertTitle } from '@mui/material';
import { HomeWork } from '@mui/icons-material';

import { objectJSON } from '../GuiasAdmin/Shipping/MarcoComponents/FormModel';
import AddressModal from '../GuiasAdmin/Shipping/MarcoComponents/AddressModal';

function FormularioDeCotizaciones() {

  //Constante de navegación
  const navigation = useNavigate();

  //Variables de error
  const [error, setError] = useState(false);
  const [tituloError, setTituloError] = useState();
  const [mensajeError, setMensajeError] = useState([]);
  
  //Variables del formulario
  const [esSobre, setEsSobre] = useState(true);
  const [codigoOrigen, setCodigoOrigen] = useState("");
  const [codigoDestino, setCodigoDestino] = useState("");
  const [peso, setPeso] = useState();
  const [alto, setAlto] = useState(null);
  const [ancho, setAncho] = useState(null);
  const [largo, setLargo] = useState(null);

  //Estado para abrir y cerrar el modal de direcciones 
  const [ openAddressModal , setOpenAddressModal ] = useState( false );

  //Estados para verificar ¿A quien se le va a cargar la direccion?
  const [ isOriginOrDestination , setIsOriginOrDestination ] = useState( 'Origen' );
  const [ originHelperText , setOriginHelperText ] = useState( '***' );
  const [ destinationHelperText , setDestinationHelperText ] = useState( '***' );

  //Variables para ingresar a las variables
  const catchEsSobre = (event) => setEsSobre(event.target.value);
  const catchCodigoOrigen = (event) => { 
    setCodigoOrigen(event.target.value);

    objectJSON.data.origin.origenEmpresa = "";
    objectJSON.data.origin.origenPersona.nombre = "";
    objectJSON.data.origin.origenPersona.apellidoPaterno = "";
    objectJSON.data.origin.origenPersona.apellidoMaterno = "";
    objectJSON.data.origin.id_dom_origen = null;
    objectJSON.data.origin.origenCalle = "";
    objectJSON.data.origin.origenNumExt = "";
    objectJSON.data.origin.origenNumInt = "";
    objectJSON.data.origin.origenColonia = "";
    objectJSON.data.origin.origenMunicipio = "";
    objectJSON.data.origin.origenEstado = "";
    objectJSON.data.origin.origenPais = "";
    objectJSON.data.origin.origenRef = "";
    objectJSON.data.origin.origenCP = "";
    objectJSON.data.origin.origenTelefono1 = "";
    objectJSON.data.origin.origenTelefono2 = "";
    objectJSON.data.origin.isLoading = false;

    setOriginHelperText( '***' );
  }
  const catchCodigoDestino = (event) => { 
    setCodigoDestino(event.target.value);
    
    objectJSON.data.destination.destinoEmpresa = "";
    objectJSON.data.destination.destinoPersona.nombre = "";
    objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
    objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
    objectJSON.data.destination.id_dom_destino = null;
    objectJSON.data.destination.destinoCalle = "";
    objectJSON.data.destination.destinoNumExt = "";
    objectJSON.data.destination.destinoNumInt = "";
    objectJSON.data.destination.destinoColonia = "";
    objectJSON.data.destination.destinoMunicipio = "";
    objectJSON.data.destination.destinoEstado = "";
    objectJSON.data.destination.destinoPais = "";
    objectJSON.data.destination.destinoRef = "";
    objectJSON.data.destination.destinoCP = "";
    objectJSON.data.destination.destinoTelefono1 = "";
    objectJSON.data.destination.destinoTelefono2 = "";
    objectJSON.data.destination.isLoading = false;

    setDestinationHelperText( '***' );
  }
  const catchPeso = (event) => setPeso(event.target.value);
  const catchAlto = (event) => setAlto(event.target.value);
  const catchAncho = (event) => setAncho(event.target.value);
  const catchLargo = (event) => setLargo(event.target.value);
  
  const styles = {
    marginY: 5, 
    border: '2px solid #efeff0',
    boxShadow: 24,
    pt: 3,
    px: 4,
    pb: 3
  };

  const envConfig = {
    url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
  }

  //Funciones para nuestro modal de direcciones
  const handleOpenAddressModal = () => {
    setOpenAddressModal( true );
  }

  const handleCloseAddressModal = () => {
      setOpenAddressModal( false );
  }

  const selectingAndLoadingAnAddress = ( address ) => {
    if( isOriginOrDestination === 'Origen' ){
      objectJSON.data.origin.origenEmpresa = ( address.empresa !== null ) ? address.empresa : '' ;
      objectJSON.data.origin.origenPersona.nombre = address.nombre;
      objectJSON.data.origin.origenPersona.apellidoPaterno = address.apellidoPaterno;
      objectJSON.data.origin.origenPersona.apellidoMaterno = ( address.apellidoMaterno !== null ) ? address.apellidoMaterno : '';
      objectJSON.data.origin.id_dom_origen = address.id;
      objectJSON.data.origin.origenCalle = address.calle;
      objectJSON.data.origin.origenNumExt = address.numeroExterior;
      objectJSON.data.origin.origenNumInt = ( address.numeroInterior !== null ) ? address.numeroInterior : '';
      objectJSON.data.origin.origenColonia = address.colonia;
      objectJSON.data.origin.origenMunicipio = address.municipio;
      objectJSON.data.origin.origenEstado = address.estado;
      objectJSON.data.origin.origenPais = address.pais;
      objectJSON.data.origin.origenRef = ( address.referencia !== null ) ? address.referencia : '';
      objectJSON.data.origin.origenCP = address.codigoPostal;
      objectJSON.data.origin.origenTelefono1 = address.telefono1;
      objectJSON.data.origin.origenTelefono2 = ( address.telefono2 !== null ) ? address.telefono2 : '';
      objectJSON.data.origin.isLoading = true;
      setCodigoOrigen( address.codigoPostal );
      setOriginHelperText(`${ objectJSON.data.origin.origenCalle }, ${ objectJSON.data.origin.origenColonia }, ${ objectJSON.data.origin.origenMunicipio }, ${ objectJSON.data.origin.origenEstado }...`);
      return;
    }

    objectJSON.data.destination.destinoEmpresa = ( address.empresa !== null ) ? address.empresa : '' ;
    objectJSON.data.destination.destinoPersona.nombre = address.nombre;
    objectJSON.data.destination.destinoPersona.apellidoPaterno = address.apellidoPaterno;
    objectJSON.data.destination.destinoPersona.apellidoMaterno = ( address.apellidoMaterno !== null ) ? address.apellidoMaterno : '';
    objectJSON.data.destination.id_dom_destino = address.id;
    objectJSON.data.destination.destinoCalle = address.calle;
    objectJSON.data.destination.destinoNumExt = address.numeroExterior;
    objectJSON.data.destination.destinoNumInt = ( address.numeroInterior !== null ) ? address.numeroInterior : '';
    objectJSON.data.destination.destinoColonia = address.colonia;
    objectJSON.data.destination.destinoMunicipio = address.municipio;
    objectJSON.data.destination.destinoEstado = address.estado;
    objectJSON.data.destination.destinoPais = address.pais;
    objectJSON.data.destination.destinoRef = ( address.referencia !== null ) ? address.referencia : '';
    objectJSON.data.destination.destinoCP = address.codigoPostal;
    objectJSON.data.destination.destinoTelefono1 = address.telefono1;
    objectJSON.data.destination.destinoTelefono2 = ( address.telefono2 !== null ) ? address.telefono2 : '';
    objectJSON.data.destination.isLoading = true;
    setCodigoDestino( address.codigoPostal );
    setDestinationHelperText(`${ objectJSON.data.destination.destinoCalle }, ${ objectJSON.data.destination.destinoColonia }, ${ objectJSON.data.destination.destinoMunicipio }, ${ objectJSON.data.destination.destinoEstado }...`);
  }

  function datosValidos () {
    setMensajeError([]);
    let hayAlerta = false;
    //Validación del código de origen
    if(!(codigoOrigen.length === 5)){
      setMensajeError((prevMensajes)=>[...prevMensajes, "- El código postal de origen debe de tener 5 dígitos."]);
      hayAlerta = true;
    }
    //Validación del código de destino
    if(!(codigoDestino.length === 5)){
      setMensajeError((prevMensajes)=>[...prevMensajes, "- El código postal de destino debe de tener 5 dígitos."]);
      hayAlerta = true;
    }
    //Validación del peso
    if(!peso){
      setMensajeError((prevMensajes)=>[...prevMensajes, "- Rellene el campo de peso."]);
      hayAlerta = true;
    }else if(peso.match(/[a-z]/i)){
      setMensajeError((prevMensajes)=>[...prevMensajes, "- Peso es un valor numérico."]);
      hayAlerta = true;
    }
    if(hayAlerta){
      setError(true);
      setTituloError("Rellene los datos correctamente");
      return false;
    }else{
      return true;
    }
  }

  const crearCotizacion = async () => {
    const url = envConfig.url + '/api/v1/adminCotizacion/cotizacion/get';
    if(datosValidos()){
      setError(false);
      setMensajeError([]);
      var peticion = {
        "data": {
          "user_id": 2,
          "origen": codigoOrigen,
          "destino": codigoDestino,
          "peso": parseFloat(peso),
          "alto": alto === null ? 0 : parseInt(alto),
          "ancho": ancho === null ? 0 : parseInt(ancho),
          "largo": largo === null ? 0 : parseInt(largo),
          "esSobre": esSobre
        }
      };
    }else{
      return;
    }
    
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(peticion)
    }).then((respuesta)=>{
      if(respuesta.ok){
        return respuesta.json();
      }else{
        setError(true);
        setTituloError("Hubo un problema con la petición");
        setMensajeError(["Por favor intente de nuevo en unos momentos"]);
      }
    }).then((data)=>{
      switch (data.status) {
        case 200:
          setError(false);
          navigation("/listQuotes", {state: {data: {cotizaciones: data.data, detalles: peticion.data}}});
          break;
        
        case 204:
          setError(true);
          setTituloError("Hubo un problema con la petición");
          setMensajeError([data.msj]);
          break;
      
        default:
          break;
      }
    }).catch((error)=>{
      setError(true);
      setTituloError("Hubo un problema con la petición");
      // setMensajeError("No logramos establecer una conexión con el servidor");
      setMensajeError([error]);
    });
  };

  const resetWaybillObject = () => {
    objectJSON.data.origin.origenEmpresa = "";
    objectJSON.data.origin.origenPersona.nombre = "";
    objectJSON.data.origin.origenPersona.apellidoPaterno = "";
    objectJSON.data.origin.origenPersona.apellidoMaterno = "";
    objectJSON.data.origin.id_dom_origen = null;
    objectJSON.data.origin.origenCalle = "";
    objectJSON.data.origin.origenNumExt = "";
    objectJSON.data.origin.origenNumInt = "";
    objectJSON.data.origin.origenColonia = "";
    objectJSON.data.origin.origenMunicipio = "";
    objectJSON.data.origin.origenEstado = "";
    objectJSON.data.origin.origenPais = "";
    objectJSON.data.origin.origenRef = "";
    objectJSON.data.origin.origenCP = "";
    objectJSON.data.origin.origenTelefono1 = "";
    objectJSON.data.origin.origenTelefono2 = "";
    objectJSON.data.origin.isLoading = false;

    objectJSON.data.destination.destinoEmpresa = "";
    objectJSON.data.destination.destinoPersona.nombre = "";
    objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
    objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
    objectJSON.data.destination.id_dom_destino = null;
    objectJSON.data.destination.destinoCalle = "";
    objectJSON.data.destination.destinoNumExt = "";
    objectJSON.data.destination.destinoNumInt = "";
    objectJSON.data.destination.destinoColonia = "";
    objectJSON.data.destination.destinoMunicipio = "";
    objectJSON.data.destination.destinoEstado = "";
    objectJSON.data.destination.destinoPais = "";
    objectJSON.data.destination.destinoRef = "";
    objectJSON.data.destination.destinoCP = "";
    objectJSON.data.destination.destinoTelefono1 = "";
    objectJSON.data.destination.destinoTelefono2 = "";
    objectJSON.data.destination.isLoading = false;

    objectJSON.data.kilosServiceAndNumber.numero = "";
    objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = 0;
    objectJSON.data.kilosServiceAndNumber.kg = 1.0;
    objectJSON.data.kilosServiceAndNumber.alto = 1;
    objectJSON.data.kilosServiceAndNumber.ancho = 1;
    objectJSON.data.kilosServiceAndNumber.largo = 1;

    objectJSON.data.whoIsPaying.costoDeEnvio = 1.0;
    objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;
    objectJSON.data.whoIsPaying.montoACobrar = 1.0;
    objectJSON.data.whoIsPaying.origenDestino = "Origen";

    objectJSON.data.isQuote = false;

    objectJSON.data.waybillGeneratedByUser.generadoPorUsuario = 0;
  };

  useEffect( resetWaybillObject , [] );

    return (
      <>
        <Container maxWidth="sm">
          <Paper sx={styles}>

            <Grid container alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12} xl={12}>
                <Grid container justifyContent="flex-start">
                  <Typography variant="h5">
                    Ingrese los siguientes datos
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <FormControl variant="outlined" sx={{minWidth: 130}} size="small">
                  <InputLabel id="lblEsSobre">Es un sobre</InputLabel>
                  <Select labelId="lblEsSobre" id="selectorEsSobre" label="Es un sobre" value={esSobre} onChange={catchEsSobre}>
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} xl={12}>
                <Grid container justifyContent="flex-start">
                  <Typography variant="subtitle2">
                    Datos del envio
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div
                      style = {{
                        display : "flex",
                        alignItems : "center",
                        gap : "5px"
                      }}
                    >
                      <TextField 
                        required 
                        variant="outlined" 
                        id="txtCodigoOrigen" 
                        label="Código postal de origen" 
                        value={codigoOrigen} 
                        onChange={catchCodigoOrigen}
                        fullWidth={true}
                        size="small"
                        helperText={originHelperText}
                      />

                      <IconButton
                        sx={{
                            backgroundColor : "#008e1e",
                            color : "#ffffff",
                            width : "35px",
                            height : "35px",
                            borderRadius : 2,
                            border : "2px solid #008e1e",
                            marginTop : "-23px",
                            "&:hover" : {
                                backgroundColor : "#006b17",
                                color : "#dcdcdc",
                                border : "2px solid #006b17"
                            }
                        }}
                        onClick={ () => {
                          setIsOriginOrDestination( 'Origen' );
                          handleOpenAddressModal();
                        }}
                        title="Cargar Dirección" 
                      >
                          <HomeWork />
                      </IconButton>
                    </div>
                  </Grid>
                      
                  <Grid item xs={12} md={12}>
                    <div
                      style = {{
                        display : "flex",
                        alignItems : "center",
                        gap : "5px"
                      }}
                    >
                      <TextField 
                        required 
                        variant="outlined" 
                        id="txtCodigoDestino" 
                        label="Código postal de destino" 
                        value={codigoDestino} 
                        onChange={catchCodigoDestino}
                        fullWidth={true}
                        size="small"
                        helperText={destinationHelperText}
                      />

                      <IconButton
                        sx={{
                            backgroundColor : "#008e1e",
                            color : "#ffffff",
                            width : "35px",
                            height : "35px",
                            borderRadius : 2,
                            border : "2px solid #008e1e",
                            marginTop : "-23px",
                            "&:hover" : {
                                backgroundColor : "#006b17",
                                color : "#dcdcdc",
                                border : "2px solid #006b17"
                            }
                        }}
                        onClick={ () => { 
                          setIsOriginOrDestination( 'Destino' );
                          handleOpenAddressModal(); 
                        }}
                        title="Cargar Dirección" 
                    >
                        <HomeWork />
                    </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <TextField 
                  required 
                  variant="outlined" 
                  id="txtPeso" 
                  label="Peso de la pieza" 
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                  }} 
                  value={peso} 
                  onChange={catchPeso}
                  size="small"
                />
              </Grid>
              {
                esSobre === false ? (
                  <Grid item>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={12} xl={12}>
                        <Grid container justifyContent="flex-start">
                          <Typography variant="subtitle2">
                            Dimensiones del paquete
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={4} xl={4}>
                        <TextField 
                          variant="outlined" 
                          id="txtPeso" 
                          label="Alto" 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>
                          }} 
                          value={alto} 
                          onChange={catchAlto}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={4} xl={4}>
                        <TextField 
                          variant="outlined" 
                          id="txtPeso" 
                          label="Ancho" 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>}} 
                          value={ancho} 
                          onChange={catchAncho}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={4} xl={4}>
                        <TextField 
                          variant="outlined" 
                          id="txtPeso" 
                          label="Largo" 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>
                          }} 
                          value={largo} 
                          onChange={catchLargo}
                          size="small"
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                ):(
                  ""
                )
              }

              <Grid item xs={12} xl={12}>
                <Button variant="contained" onClick={crearCotizacion}>Realizar cotizacion</Button>
              </Grid>

              {
                error ? (
                  <Grid item xs={12} xl={12}>
                    <Alert severity='error'>
                      <AlertTitle>{tituloError}</AlertTitle>
                      <Grid container justyfyContent= "flex-start" alignItems= "flex-start">
                        {mensajeError.map((mensaje)=><Grid item>{mensaje}</Grid>)}
                      </Grid>
                    </Alert>
                  </Grid>
                ):(
                  ""
                )
              }
            </Grid>
          </Paper>
        </Container>

        <AddressModal 
          open={openAddressModal}
          handleClose={handleCloseAddressModal}
          selectingAndLoadingAnAddress={selectingAndLoadingAnAddress}
        />
      </>
    );
}

export default FormularioDeCotizaciones