import React, {useState, useEffect} from 'react';
import {
    Paper,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@mui/material';

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function FormFilter(props){
    const {
            formValue,
            setFormValue,
            submit
        }=props;

    const [dateRange, setDateRange] = useState([
        {
        startDate: null,
        endDate: new Date(),
        key: 'selection'
        }
    ]);

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };

    const handleChangeProcess=(e)=>{
        e.preventDefault();
        setFormValue({
            ...formValue,
            [e.target.name]:e.target.value
        });
    }
    useEffect(()=>{
        let startDate= (dateRange[0]["startDate"]!==null)?(dateRange[0]["startDate"]).toLocaleDateString():null
        let endDate= (dateRange[0]["endDate"]!==null)?(dateRange[0]["endDate"]).toLocaleDateString():null
        
        setFormValue({
            ...formValue,
            "startDate":startDate,
            "endDate":endDate,
        });
    },[dateRange])

    const handleChangeDate=(item)=>{
        setDateRange([item.selection]);
    }
    const [procesos, setProcesos] = useState(
        [
            {
                id:1,
                name:"Creacion"
            },
            {
                id:2,
                name:"Llegado a Bodega"
            },
            {
                id:3,
                name:"Devolucion"
            },
            {
                id:4,
                name:"Entrega a Driver"
            },
            {
                id:5,
                name:"Recibido de driver"
            }
            
        ]);
    
    const clearForm = (e)=>{
        e.preventDefault();
        window.location.reload();
    };

    const getProcess = async ()=>{
        try{
            const data = await fetch(envConfig.url+'/api/v1/process/all?', {method: 'GET'})
                                    .then((response)=>response.json());
            setProcesos(data["data"]);
            console.log(data["data"]);
        }
        catch(error){
            console.log(error);
        }
    }

    useEffect(()=>{
        getProcess();
    },[])
    return (
        <Grid item xs={12}
                sx={{
                    display:'flex',
                    flexDirection: 'row',
                    height: 400,
                }}
        >
            <Paper sx={{ 
                m: 2, 
                p: 2, 
                display: 'flex', 
                flexDirection: 'column',
                width: "100%",
                }}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={handleChangeDate}
                        moveRangeOnFirstSelection={true}
                        ranges={dateRange}
                    />
            </Paper>
            <Paper sx={{ 
                m: 2, 
                p: 2, 
                display: 'flex', 
                flexDirection: 'column',
                width: "100%",
                }}>
                <FormGroup>
                    <FormControl>
                        <InputLabel htmlFor="my-input">Proceso</InputLabel>
                        <Select
                                labelId="processLabel"
                                id="opMovement"
                                name="opMovement"
                                label="opMovement"
                                value={formValue["opMovement"]}
                                onChange={handleChangeProcess}
                            >
                                <MenuItem name="process" value="0">Seleccione</MenuItem>
                                {procesos.map(
                                    (item)=>
                                    <MenuItem key={item.id} name="process" value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                )}
                            </Select>
                    </FormControl>
                </FormGroup>
                <Button sx={{mt: 2}} variant="contained" type="submit" onClick={submit}>Buscar</Button>
                <Button sx={{mt: 2}} variant="text" type="submit" onClick={clearForm}>Limpiar</Button>
            </Paper>
        </Grid>

    )
}