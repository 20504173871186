
import React, {useState} from 'react';

import {
    Grid,
    Paper,
    Container,
    TextareaAutosize,
    Button,
    List,
    ListItem
} from '@mui/material';

import Copyright from '../../../General/Copyright';
import TrackingItem from './trackingItem';

function TrackingView(){
    
    const [open, setOpen] = useState(false);
    
    const [items, setItems] = useState([]);
    const [waybillsToSearch, setWaybillsToSearch] = useState([]);
    const [waybillResult, setwaybillResult] = useState([]);
    const [waitForAResult, setWaitForAResult] = useState(false);
    
    const handleClickOpen = (waybill) => {
        setOpen(true);
        setItems(waybill);
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    
    const handleChange = (e)=>{
        e.preventDefault();
        setWaybillsToSearch(e.target.value.split("\n"));
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setWaitForAResult(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"waybills":waybillsToSearch})
        };
    
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };
    

        try{
            const response = await fetch(envConfig.url+'/api/v1/routing/get', requestOptions);
            const data = await response.json();
            console.log(data);
            setwaybillResult(data["data"]);
        }
        catch(error){
            alert("Error de conexion, intente mas tarde")
            console.log(error);
        }

        setWaitForAResult(false);
    }
    
    return(
        <Grid container spacing={3}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        }}
            >
            <Grid item xs={12}>
                <Paper sx={{ 
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    height: 100,
                    }}>
                    Rastreo
                </Paper>
            </Grid>
            <Grid item xs={12} 
                sx={{  
                    display: 'flex', 
                    flexDirection: 'row',
                    justifyContent:"space-around"
                    }}>
                    <Paper
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        width:500,
                        p:2
                        }}
                    >
                        <TextareaAutosize
                            minRows={10}
                            maxRows={10}
                            aria-label="maximum height"
                            placeholder="Inserte guias rastreo"
                            onChange={handleChange}
                            sx={{
                                width: "100%",
                            }}
                            />
                        <Button 
                                variant="contained" 
                                type="submit"
                                onClick={handleSubmit}
                                sx={{
                                    mt:2
                                }}
                                disabled = { waitForAResult }
                                >Rastrear</Button>
                        
                    </Paper>
                {/* Recent Deposits */}
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        width:500,
                        overflow: 'auto',
                        }}
                    >
                        <List>
                                {waybillResult.map((item,id)=>(
                                    (item.length===0)?"":
                                    <ListItem key={id} button variant="outlined" onClick={()=>handleClickOpen(item)} sx={{mt:1}}>
                                        Guia: {item[0]['guia']}
                                    </ListItem>
                                    
                                    )
                                )}
                        </List>
                        <TrackingItem
                            open={open}
                            onClose={handleClose}
                            items={items}
                            title={"Rastreo "}
                        />
                    </Paper>
            </Grid>
        </Grid>
    )
}

export default function TrackingIndex(props) {

    return(
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <TrackingView/>,
            <Grid container paddingTop={2}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                            p: 1,
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems:'center',
                            justifyContent:'center',
                            height: 50,
                        }}>
                        <Copyright sx={{ pt: 4 }} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
