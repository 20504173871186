import React from 'react';
import {Grid,
        Card,
        CardContent,
        CardHeader,
        Typography,
        TextField,
        InputAdornment,
        InputBase} from '@mui/material';
import {AttachMoney,
        Home} from '@mui/icons-material';

import { objectJSON } from './FormModel';

export const methodOfPaymentIs = {
    methodOfPayment : "Origen",
    icon : <Home fontSize="small" sx={{ color: "#ff4500" }}/>, 
};

export default function TheMethodOfPaymentIs(props){

    const cardCSS = {
        card :{ 
            methodOfPayment : {
                backgroundColor : '#028437',
                color: '#ffffff',
                border : '2px solid #1ccd65',
                height: '67px'
            }
        },
        
        cardContent : {
            marginTop : '5px',
            marginBottom : '-20px',
            display : 'flex',
            justifyContent : 'space-evenly',
            fontSize : '14px',
            fontWeight : 'bold'
        }
    };

    return(
        <Grid container spacing = {3}>
            <Grid item xs = {12} md = {4}>
                <TextField 
                    id="outlined-basic"
                    variant="outlined"
                    value={ methodOfPaymentIs.methodOfPayment }
                    disabled
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                { methodOfPaymentIs.icon }
                            </InputAdornment>
                        ),
                        style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            width: "120px"
                        }
                    }}
                    sx={{
                        "& .MuiInputBase-root.Mui-disabled": {
                            border: "2px solid #ff4500",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000"
                        }
                    }}
                    size="small"
                />
            </Grid>
            <Grid item xs = {12} md = {5}>
                <Typography 
                    variant="body2"
                    fontWeight="bold"
                >
                    Pagara el envió, con el monto de
                </Typography>
            </Grid>
            <Grid item xs = {12} md = {3}>
                <TextField 
                    id="outlined-basic"
                    variant="outlined"
                    value={ objectJSON.data.whoIsPaying.montoACobrar }
                    disabled
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AttachMoney fontSize="small" sx={{ color : "#ff4500" }}/>
                            </InputAdornment>
                        ),
                        style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            width: "120px"
                        }
                    }}
                    sx={{
                        "& .MuiInputBase-root.Mui-disabled": {
                            border: "2px solid #ff4500",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000"
                        }
                    }}
                    size="small"
                />
            </Grid>
        </Grid>
    );
}

{/* <Card
            sx={{ 
                minWidth: 275,
                paddingLeft : '35px',
                paddingRight : '35px',
            }}>
            <CardContent>
                <Grid 
                    container
                    spacing = { 1 }
                >
                    <Grid
                        item
                        xs = { 8 }
                    >
                        <Card
                            variant = "outlined"
                            sx = {{
                                backgroundColor : '#aba8a8',
                                color: '#ffffff',
                                border : '2px solid #5b5b5b',
                            }}>
                            <CardContent
                                sx = {{}}
                            >
                                El monto a pagar sera de : <span sx = {{ color : '#028437' }} >{ objectJSON.data.whoIsPaying.montoACobrar }</span>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs = { 4 }
                    >
                        <Card
                            sx = { cardCSS.card.methodOfPayment }>
                            <CardContent
                                sx = { cardCSS.cardContent }
                            >
                                { methodOfPaymentIs.icon } { methodOfPaymentIs.methodOfPayment }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card> */}