import React , { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Grid,
        Typography,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper,
        Button,
        Card,
        Container,
        CardContent,
        IconButton} from '@mui/material';
import {Download,
        Cancel,
        NoteAdd,
        LibraryAdd} from '@mui/icons-material';

import './shippingStyles.css';
import ButtonLink from './MarcoComponents/ButtonLink';
import DownloadButton from './MarcoComponents/DownloadButton';
import ConfirmationDialog from './MarcoComponents/ConfirmationDialog';
import { objectJSON } from './MarcoComponents/FormModel';

export default function WaybillsTable(props){

    /* Estados */
    const [ open , setOpen ] = useState(false);
    const [ waybillNumber , setWaybillNumber ] = useState('');
    const [ arrayWaybills , setArrayWaybills ] = useState([]);
    const navigate = useNavigate();

    /* Objeto user de Session Storage */
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };
    
    /* Objeto de estilos para la tabla de guias */
    const waybillsTableCSS = {
        typography : { 
            fontWeight : '600',
            display : 'flex',
            marginLeft : '5px'
        },
        subTypography : {
            marginLeft : '5px',
            color: '#ff4500'
        },
        downloadButton : {
            fontSize : "10px",
            color : "#ffffff",
            backgroundColor : "#0227e2",
            border : "2px solid #006ff4",
            "&:hover" : {
                backgroundColor : "#011ca4",
                border : "2px solid #014ca6",
            }
        },
        cancelButton : {
            fontSize : "10px",
            color : "#ffffff",
            backgroundColor : "#e91104",
            border : "2px solid #fa4f44",
            "&:hover" : {
                backgroundColor : "#b00a00",
                border : "2px solid #bf3d35",
            },
            marginTop : "15px",
        },
        textData : {
            color : '#ff4500'
        }
    };

    /* Arreglo de objetos de nombres para las columnas */
    const columns = [
        {
            column : 'Fecha',
            width : 180
        }, {
            column : 'Numero de Guia',
            width : 180
        }, {
            column : 'Origen',
            width : 295
        }, {    
            column : 'Destino',
            width : 295
        }, {    
            column : 'Servicio',
            width : 180
        }, {
            column : 'Kilos Amparados',
            width : 180
        }, {
            column : 'Acciones',
            width : 180
        }
    ];

    /* Generando columnas */
    const tableCellsHead = columns.map( ( item , index ) => (
        <TableCell>
            { item.column }
        </TableCell>
    ));
    
    /* Generando los renglones de la tabla de guias */
    const waybillsTableRows = arrayWaybills.map( ( item , index ) => {
        const destinationData = [
            {
                label : 'Nombre: ',
                data : item.destinoPersona
            },
            {
                label : 'Municipio: ',
                data : item.destinoMunicipio
            },
            {
                label : 'Estado: ',
                data : item.destinoEstado
            },
            {
                label : 'C.P: ',
                data : item.destinoCP
            },
        ];

        const originData = [
            {
                label : 'Nombre: ',
                data : item.origenPersona
            },
            {
                label : 'Municipio: ',
                data : item.origenMunicipio
            },
            {
                label : 'Estado: ',
                data : item.origenEstado
            },
            {
                label : 'C.P: ',
                data : item.origenCP
            },
        ];

        const date = new Date( item.created_at );
        const month = ( date.getMonth() < 9 ) ? '0' + String( date.getMonth() + 1 ) : date.getMonth() + 1 ;
        const monthDay = ( date.getDate() < 10 ) ? '0' + String( date.getDate() ) : date.getDate();

        return (
            <TableRow>
                <TableCell sx = { waybillsTableCSS.textData }>
                    { monthDay }/{ month }/{ date.getFullYear() }
                </TableCell>

                <TableCell sx = { waybillsTableCSS.textData }>
                    { item.numero }
                </TableCell>

                <TableCell>
                    {
                        originData.map( ( data ) => (
                            <div>
                                <Typography variant = "body2" component = "div" sx = { waybillsTableCSS.typography }>
                                    { data.label }
                                </Typography>

                                <Typography variant = "body2" sx = { waybillsTableCSS.subTypography }>
                                    { data.data }
                                </Typography>
                            </div> 
                        ))
                    }
                </TableCell>

                <TableCell>
                    {
                        destinationData.map( ( data ) => (
                            <div>
                                <Typography variant = "body2" component = "div" sx = { waybillsTableCSS.typography }>
                                    { data.label }
                                </Typography>

                                <Typography variant = "body2" sx = { waybillsTableCSS.subTypography }>
                                    { data.data }
                                </Typography>
                            </div>
                            
                        ) )
                    }
                </TableCell>
                <TableCell 
                    align = "center"
                    sx = { waybillsTableCSS.textData }
                >
                    { item.tipo_servicio_id }
                </TableCell>
                <TableCell 
                    align = "center"
                    sx = { waybillsTableCSS.textData }
                >
                    { item.kg }
                </TableCell>
                <TableCell
                    align = "center"
                >
                    <DownloadButton
                        sx = { waybillsTableCSS.downloadButton }
                        waybill = { item }
                    />

                    {/* <Button
                        sx = { waybillsTableCSS.cancelButton }
                        variant = "contained"
                        onClick = { () => {
                            setWaybillNumber( item.numero );
                            handleClickOpen();
                        }}
                    >
                        <Cancel fontSize = "small"/>
                        Cancelar Guia
                    </Button> */}
                </TableCell>
            </TableRow>
        ) 
    });

    const goTo = ( URL ) => {
        navigate( URL );
    }

    /* Abriendo la ventana de confirmacion */
    const handleClickOpen = () => {
        setOpen( true );
    }

    /* Cerrando la ventana de confirmacion */
    const handleClose = () => {
        setOpen( false );
    }

    /* Cargando las guias creadas por el usuario */
    const getWaybills = async () => {
        const URL = envConfig.url + '/api/v1/wayBills/getWaybills';

        const userID = {
            data : {
                user_id : userData.id
            }
        }

        /*await fetch( envConfig.url+'/api/v1/wayBills/getWaybills' , {*/
        await fetch( URL , {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify( userID )
        })
            .then( (res) => {
                if(!res.ok){
                    alert('Algo ocurrio: ' + res.status);
                }

                return res.json();
            })
            .then( data => setArrayWaybills( data.data ) )
            .catch( error => alert('Algo ocurrio: ' + error) );
    }

    /* Reseteando el objeto de origen y destino */
    function clearDataObject(){
        objectJSON.data.origin.origenEmpresa = "";
        objectJSON.data.origin.origenPersona.nombre = "";
        objectJSON.data.origin.origenPersona.apellidoPaterno = "";
        objectJSON.data.origin.origenPersona.apellidoMaterno = "";
        objectJSON.data.origin.id_dom_origen = null;
        objectJSON.data.origin.origenCalle = "";
        objectJSON.data.origin.origenNumExt = "";
        objectJSON.data.origin.origenNumInt = "";
        objectJSON.data.origin.origenColonia = "";
        objectJSON.data.origin.origenMunicipio = "";
        objectJSON.data.origin.origenEstado = "";
        objectJSON.data.origin.origenPais = "";
        objectJSON.data.origin.origenRef = "";
        objectJSON.data.origin.origenCP = "";
        objectJSON.data.origin.origenTelefono1 = "";
        objectJSON.data.origin.origenTelefono2 = "";

        objectJSON.data.destination.destinoEmpresa = "";
        objectJSON.data.destination.destinoPersona.nombre = "";
        objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
        objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
        objectJSON.data.destination.id_dom_destino = null;
        objectJSON.data.destination.destinoCalle = "";
        objectJSON.data.destination.destinoNumExt = "";
        objectJSON.data.destination.destinoNumInt = "";
        objectJSON.data.destination.destinoColonia = "";
        objectJSON.data.destination.destinoMunicipio = "";
        objectJSON.data.destination.destinoEstado = "";
        objectJSON.data.destination.destinoPais = "";
        objectJSON.data.destination.destinoRef = "";
        objectJSON.data.destination.destinoCP = "";
        objectJSON.data.destination.destinoTelefono1 = "";
        objectJSON.data.destination.destinoTelefono2 = "";

        objectJSON.data.kilosServiceAndNumber.numero = "";
        objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = 0;
        objectJSON.data.kilosServiceAndNumber.kg = 1.0;
        objectJSON.data.kilosServiceAndNumber.alto = 1;
        objectJSON.data.kilosServiceAndNumber.ancho = 1;
        objectJSON.data.kilosServiceAndNumber.largo = 1;

        objectJSON.data.whoIsPaying.montoACobrar = 1.0;
        objectJSON.data.whoIsPaying.costoDeEnvio = 1.0;
        objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;
        objectJSON.data.whoIsPaying.origenDestino = "Origen";

        objectJSON.data.isQuote = false;
    }

    useEffect( () => getWaybills() , [] );
    useEffect( () => clearDataObject() , [] );
    // useEffect( () => alert('La respuesta es: ' + JSON.stringify(arrayWaybills)) , [ arrayWaybills ]  );

    /* Retorno de la vista del modulo */
    return(
        <Container maxWidth="lg">
            <Paper elevation={3} sx={ { marginY: 5, paddingX: 5, paddingY: 5} }>

                {/* Encabezado de la página */}
                <Container sx={ {marginBottom: 2, textAlign: "left"} }>
                    <Grid container spacing={2}>

                        {/* Titulo */}
                        <Grid item xs={12} md={9}>
                            <Typography variant='h5'>
                                Tus guias
                            </Typography>
                        </Grid>

                        {/* Botones */}
                        <Grid item xs={12} md={3}>
                            <div 
                                style = {{
                                    display : "flex",
                                    justifyContent : "right",
                                    gap : 8
                                }}
                            >
                                {/* <ButtonLink url = "/originOrDestination" clases = "" texto = "Crear guia" /> */}
                                <IconButton 
                                    onClick = { () => goTo( "/massiveWaybills" ) }
                                    title = "Crear guia masiva"
                                    sx = {{
                                        backgroundColor : "#079000",
                                        color : "#ffffff",
                                        width : "35px",
                                        height : "35px",
                                        borderRadius : 2,
                                        border : "2px solid #079000",
                                        "&:hover" : {
                                            backgroundColor : "#056a00",
                                            color : "#dcdcdc",
                                            border : "2px solid #056a00"
                                        }
                                    }}
                                >
                                    <LibraryAdd/>
                                </IconButton>

                                {/* <IconButton 
                                    onClick = { () => goTo( "/originOrDestination" ) }
                                    title = "Crear guia"
                                    sx = {{
                                        backgroundColor : "#ff4500",
                                        color : "#ffffff",
                                        width : "35px",
                                        height : "35px",
                                        borderRadius : 2,
                                        border : "2px solid #ff4500",
                                        "&:hover" : {
                                            backgroundColor : "#e93f01",
                                            color : "#dcdcdc",
                                            border : "2px solid #e93f01"
                                        }
                                    }}
                                >
                                    <NoteAdd/>
                                </IconButton> */}
                            </div>
                        </Grid>

                    </Grid>
                </Container>

                {/* Tabla con las direcciones */}
                <TableContainer>
                    <Table stickyHeader aria-label="Tabla con encabezados estáticos">

                        {/* Encabezado de la tabla */}
                        <TableHead>
                            <TableRow>
                                { tableCellsHead }
                            </TableRow>
                        </TableHead>

                        {/* Cuerpo de la tabla */}
                        <TableBody>
                            { waybillsTableRows }
                        </TableBody>
                    </Table>
                </TableContainer>
                
            </Paper>

            <ConfirmationDialog 
                open = { open }
                handleClose = { handleClose }
                waybillNumber = { waybillNumber }
            />

        </Container>
    );
}

/*

<>
            <div className = "container container--waybillsTable ">
                <div className = "containerGrid">
                    <Grid container spacing = { 2 }>
                        <Grid item xs = { 8 }
                        >
                            <Typography
                                variant = "body2"
                                sx = {{
                                    color : "#ff4500",
                                    fontWeight : "bold",
                                    fontSize : "23px",
                                    marginLeft : "270px"
                                }}
                            >
                                Tus Guias
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs = { 4 }
                        >
                            <div align = "right">
                                {/* <ButtonLink 
                                    url = "/originOrDestination"
                                    clases = ""
                                    texto = "+Agregar" 
                                /> */

                                /*<IconButton 
                                    onClick = { () => goTo( "/massiveWaybills" ) }
                                    title = "Crear guia masiva"
                                    color = "success"
                                >
                                    <LibraryAdd/>
                                </IconButton>

                                <IconButton 
                                    onClick = { () => goTo( "/originOrDestination" ) }
                                    title = "Crear guia"
                                    color = "primary"
                                >
                                    <NoteAdd/>
                                </IconButton>
                            </div>
                        </Grid>

                        <Grid item xs = { 12 }>*/

                            /* Tabla de guias }
                            <TableContainer sx = {{ maxHeight: 440 }} component = {Paper}>
                                <Table stickyHeader aria-label="sticky table">

                                    <TableHead>
                                        <TableRow>
                                            { tableCellsHead }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        { waybillsTableRows }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>

                    </Grid>
                </div>
            </div>

            <ConfirmationDialog 
                open = { open }
                handleClose = { handleClose }
                waybillNumber = { waybillNumber }
            />
        </>
*/