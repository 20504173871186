import React from 'react';
import { useNavigate } from "react-router-dom";
import {Card,
        CardContent,
        CardHeader,
        Button,
        Avatar,
        Typography,
        Box} from '@mui/material';

import '../shippingStyles.css';

export default function CardInfo(props){
    const {
        icon,
        title,
        information,
        url
    } = props;

    const navigate = useNavigate();

    const cardInfoCSS = {
        card : { 
            border : '2px solid #ff4500',
            width : '100%',
            transition: 'transform .2s',
            '&:hover': {
                boxShadow : 8,
                transform : 'scale(1.04)'
            }
        },
        avatar : { 
            backgroundColor : 'transparent',
            border: 'none',
            width: '26px',
            height: '26px'
        },
        boxHeader : {
            backgroundColor : '#ff4500',
            color: '#ffffff',
            paddingTop: '0px',
            paddingBottom: '0px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        cardContent : {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        typographyTitle : {
            fontSize: '15px',
            fontWeight: 500
        },
        typography : { 
            fontWeight : '600',
            display : 'flex',
            gap: '8px'
        },
        subTypography : {
            color: '#ff4500'
        }
    };

    const dataObject = [
        "Empresa:",
        "Nombre:",
        "Calle:",
        "Numero Exterior:",
        "Numero Interior:",
        "Colonia:",
        "Municipio:",
        "Estado:",
        "Pais:",
        "Referencia:",
        "CP:",
        "Telefono 1:",
        "Telefono 2:"
    ];

    function goTo(){
        navigate( url );
    }

    const informationArray = Object.keys( information ).map( ( keyName , i ) => {
            // if( i !== 0 ){
                if(  information[ keyName ] !== '' ){
                    return (
                        <Typography
                            key = { i }
                            variant = "body2"
                            component = "div"
                            sx = { cardInfoCSS.typography }
                        >
                            { dataObject[ i ] } 
                            <Typography 
                                variant = "body2"
                                sx = { cardInfoCSS.subTypography }
                            >
                                { information[ keyName ] }
                            </Typography>
                        </Typography>
                    );
                }
            // } 
        }
    );

    return(
        <Button
            sx = {{ textTransform : 'none' }}
            onClick = { goTo }
            fullWidth = { true }
        >
            <Card 
                variant = "outlined"
                sx = { cardInfoCSS.card }
            >
                <Box
                    sx = { cardInfoCSS.boxHeader }
                > 
                    { icon }
                    <Typography 
                        variant = 'body2'
                        sx = { cardInfoCSS.typographyTitle }
                    >
                        { title }
                    </Typography>
                </Box>
                <CardContent
                    sx = { cardInfoCSS.cardContent }
                >
                    { informationArray }
                </CardContent>
            </Card>
        </Button>
    );
}