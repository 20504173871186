import React, {useState, useEffect} from 'react';
import {
    Typography,
    Container,
    Grid,
    Paper,
    Button,
} from '@mui/material';

function RowZone(props){
    const {nameZone,valueZone} = props;
    const sxCol = {
        m:1,
    }
    return(
            <Grid item xs={12} 
                sx={{
                        display: 'flex',
                        flexDirection:'row',
                        justifyContent: 'space-around',
                        mt:1
                    }}>
                <Typography
                        component="h3"
                        variant="h6"
                        color="#000000"
                        sx={sxCol}
                        >
                        {nameZone}
                </Typography>
                <Typography
                    component="h3"
                    variant="h6"
                    color="#000000"
                    sx={sxCol}
                    >
                        {valueZone}
                </Typography>
            </Grid>
    )
}


export default function ReceivedWaybills(props){
    const zones = [
        {
            "name":"zona1",
            "value":"13"
        },
        {
            "name":"zona2",
            "value":"14"
        },
        {
            "name":"zona3",
            "value":"15"
        },
        {
            "name":"zona4",
            "value":"15"
        },
        {
            "name":"zona4",
            "value":"15"
        },
        {
            "name":"zona4",
            "value":"15"
        },
        {
            "name":"zona4",
            "value":"15"
        },
        {
            "name":"zona4",
            "value":"15"
        }
    ]

    const rowZones = zones.map((item)=><RowZone nameZone={item.name} valueZone={item.value}/>)

    return(
        <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 2, 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: 100,
                        }}>
                        <div id="popUp"></div>
                            <Typography
                                component="h3"
                                variant="h4"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                    Resumen
                            </Typography>
                    </Paper>
                </Grid>
                    {/* Chart */}
                <Grid item xs={12} md={4} lg={5}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        height: 400,
                        }}
                    >
                        <Typography
                            component="h3"
                            variant="h6"
                            color="#000000"
                            sx={{
                                pr:3
                            }}
                            >
                                Guias exitosas
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h6"
                            color="#000000"
                            sx={{
                                pr:3
                            }}
                            >
                                Guias fallidas
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h6"
                            color="#000000"
                            sx={{
                                pr:3
                            }}
                            >
                                Total de guias escaneadas
                        </Typography>

                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={8} lg={7}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        height: 400,
                        }}
                    >
                        <Typography
                            component="h4"
                            variant="h5"
                            color="#000000"
                            sx={{
                                pr:3
                            }}
                            >
                                Zonas
                        </Typography>
                        <Grid xs={12} sx={{
                                width:"100%",
                                overflow: 'auto',
                            }}>
                            {rowZones}
                        </Grid>
                    </Paper>
                </Grid>
                
            </Grid>
    );
}

