import React , { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {Card,
        CardContent,
        Grid,
        Button,
        Typography,
        Box} from '@mui/material';

import '../shippingStyles.css';
import { objectJSON } from './FormModel';

export default function CardInsurance(props){
    const {
        data,
        showingTheCirculeProgress
    } = props;

    const navigate = useNavigate();
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    const [ waitAnResult , setWaitAnResult ] = useState( false );

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
    }

    const typographyCSS = {
        typography : { 
            fontWeight : '600',
            display : 'flex',
            marginBottom : '20px',
            marginTop : '-6px'
        },
        subTypography : {
            marginLeft : '5px',
            color: '#ff4500'
        },
        cardData : {
            backgroundColor : '#ffffff',
            width : 175,
            height : 40,
            marginLeft : '36px'
        }
    };

    const sendInformationToDB = async () => {
        setWaitAnResult( true );
        showingTheCirculeProgress();
        objectJSON.data.user.user_id = userData.id;
        objectJSON.data.waybillGeneratedByUser.generadoPorUsuario = 1;

        const newObjectJSON = {
            data : {
                    waybills : [{
                                ...objectJSON.data.kilosServiceAndNumber,
                                ...objectJSON.data.origin,
                                ...objectJSON.data.destination,
                                ...objectJSON.data.whoIsPaying,
                                ...objectJSON.data.user,
                                ...objectJSON.data.waybillGeneratedByUser
                    }],
                    isThereWaybillMassive : false
            }
        };
        delete newObjectJSON.data.waybills[0].id;
        newObjectJSON.data.waybills[0].origenPersona = ( ! (  objectJSON.data.origin.origenPersona.apellidoMaterno === '' ) ) 
        ? ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno } ${ objectJSON.data.origin.origenPersona.apellidoMaterno }` ) 
        : ( `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno }`);
        newObjectJSON.data.waybills[0].destinoPersona = ( ! ( objectJSON.data.destination.destinoPersona.apellidoMaterno === '' ) )
        ? ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno } ${ objectJSON.data.destination.destinoPersona.apellidoMaterno }` )
        : ( `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno }` );
        
        // alert(JSON.stringify(newObjectJSON));
        // const URL = 'http://localhost:8000/api/v1/wayBills/store';
        // const URL = 'https://system.logisticareyes.com.mx/api/v1/wayBills/store';
        // const URL = 'https://system.logisticareyes.com.mx/api/v1/wayBills/store';
        // const URL = `https://system.test.logisticareyes.com.mx/api/v1/wayBills/store`;
        // const URL = envConfig.url + '/api/v1/wayBills/getWaybillMassive';
        const URL = envConfig.url + '/api/v1/wayBills/getWaybillMassive';

        await fetch( URL , {
            method : "POST",
            headers : {
                "Content-Type": "application/json"
            },
            body : JSON.stringify( newObjectJSON )
        } ).then((respuesta)=>{
            if(respuesta.ok){
                return respuesta.json();
            }else{
                alert("Hubo un problema con la petición, intente de nuevo en unos momentos");
            }
        })
        //.then((data)=>{
        //     alert(JSON.stringify(data));
        // })
            // .then( res => alert('Funciono :D') )
            // .catch( error => alert('Algo fallo: ' + error) );
        //     .then( res => res.blob() )
        //     .then( blob => {
        //         const download = require('downloadjs');
        //         download( blob , "Guia.pdf", "application/pdf");
        //     } )
        //     .catch( error => alert( 'Algo ocurrio :C ' + error ) );
        //     .then( (res) => {
        //         if(!res.ok){
        //             alert( 'Algo ocurrio : ' + res.status )
        //         }
        //         return res.json();
        //     })
            .then( data => { 
                objectJSON.data.origin.origenEmpresa = "";
                objectJSON.data.origin.origenPersona.nombre = "";
                objectJSON.data.origin.origenPersona.apellidoPaterno = "";
                objectJSON.data.origin.origenPersona.apellidoMaterno = "";
                objectJSON.data.origin.id_dom_origen = null;
                objectJSON.data.origin.origenCalle = "";
                objectJSON.data.origin.origenNumExt = "";
                objectJSON.data.origin.origenNumInt = "";
                objectJSON.data.origin.origenColonia = "";
                objectJSON.data.origin.origenMunicipio = "";
                objectJSON.data.origin.origenEstado = "";
                objectJSON.data.origin.origenPais = "";
                objectJSON.data.origin.origenRef = "";
                objectJSON.data.origin.origenCP = "";
                objectJSON.data.origin.origenTelefono1 = "";
                objectJSON.data.origin.origenTelefono2 = "";
                objectJSON.data.origin.isLoading = false;

                objectJSON.data.destination.destinoEmpresa = "";
                objectJSON.data.destination.destinoPersona.nombre = "";
                objectJSON.data.destination.destinoPersona.apellidoPaterno = "";
                objectJSON.data.destination.destinoPersona.apellidoMaterno = "";
                objectJSON.data.destination.id_dom_destino = null;
                objectJSON.data.destination.destinoCalle = "";
                objectJSON.data.destination.destinoNumExt = "";
                objectJSON.data.destination.destinoNumInt = "";
                objectJSON.data.destination.destinoColonia = "";
                objectJSON.data.destination.destinoMunicipio = "";
                objectJSON.data.destination.destinoEstado = "";
                objectJSON.data.destination.destinoPais = "";
                objectJSON.data.destination.destinoRef = "";
                objectJSON.data.destination.destinoCP = "";
                objectJSON.data.destination.destinoTelefono1 = "";
                objectJSON.data.destination.destinoTelefono2 = "";
                objectJSON.data.destination.isLoading = false;

                objectJSON.data.kilosServiceAndNumber.numero = "";
                objectJSON.data.kilosServiceAndNumber.tipo_servicio_id = 0;
                objectJSON.data.kilosServiceAndNumber.kg = 1.0;
                objectJSON.data.kilosServiceAndNumber.alto = 1;
                objectJSON.data.kilosServiceAndNumber.ancho = 1;
                objectJSON.data.kilosServiceAndNumber.largo = 1;

                objectJSON.data.whoIsPaying.montoACobrar = 1.0;
                objectJSON.data.whoIsPaying.costoDeEnvio = 1.0;
                objectJSON.data.whoIsPaying.valorDeMercancia = 0.0;
                objectJSON.data.whoIsPaying.origenDestino = "Origen";

                objectJSON.data.isQuote = false;

                alert(`El registro de la guia ha sido guardado con exito.\n Numero de Guia: ${ JSON.stringify( data.numero ) }`);
                navigate('/waybillsTable');
            } )
            .catch( error => alert('Algo ocurrio :C ' + error ) );
        
        showingTheCirculeProgress();
        setWaitAnResult( false );
    }

    return(
        // <Box 
        //     sx = {{ minWidth : 275 }}
        // >
        //     <Card 
        //         variant = "outlined"
        //         sx = {{
        //             backgroundColor : '#aba8a8',
        //             border : '2px solid #5b5b5b',
        //             height : 133
        //         }}
        //     >
        //         <CardContent>
        //             <Grid
        //                 container
        //                 spacing = { 2 }
        //             >
        //                 <Grid
        //                     item
        //                     xs = { 6 }
        //                 >
        //                     <Box>
        //                         <Card
        //                             variant = "outlined"
        //                             sx = { typographyCSS.cardData }
        //                         >
        //                             <CardContent>
        //                                 <Typography 
        //                                     variant = "body2"
        //                                     component = "div"
        //                                     sx = {{
        //                                         ...typographyCSS.typography,
        //                                         marginLeft : '7px'
        //                                     }}
        //                                 >
        //                                     SubTotal: 
        //                                     <Typography 
        //                                         variant = "body2"
        //                                         sx = { typographyCSS.subTypography }
        //                                     >
        //                                         ${ data.subtotal }
        //                                     </Typography>
        //                                 </Typography>
        //                             </CardContent>
        //                         </Card>
        //                     </Box>
        //                 </Grid>
        //                 <Grid
        //                     item
        //                     xs = { 6 }
        //                 >
        //                     <Box>
        //                         <Card
        //                             variant = "outlined"
        //                             sx = { typographyCSS.cardData }
        //                         >
        //                             <CardContent>
        //                                 <Typography 
        //                                     variant = "body2"
        //                                     component = "div"
        //                                     sx = {{
        //                                         ...typographyCSS.typography,
        //                                         marginLeft : '23px'
        //                                     }}
        //                                 >
        //                                     Total: 
        //                                     <Typography 
        //                                         variant = "body2"
        //                                         sx = { typographyCSS.subTypography }
        //                                     >
        //                                         ${ data.total }
        //                                     </Typography>
        //                                 </Typography>
        //                             </CardContent>
        //                         </Card>
        //                     </Box>
        //                 </Grid>
        //                 <Grid
        //                     item
        //                     xs = { 6 }
        //                 >
        //                     <Box>
        //                         <Card
        //                             variant = "outlined"
        //                             sx = { typographyCSS.cardData }
        //                         >
        //                             <CardContent>
        //                                 <Typography 
        //                                     variant = "body2"
        //                                     component = "div"
        //                                     sx = {{
        //                                         ...typographyCSS.typography,
        //                                         marginLeft : '30px'
        //                                     }}
        //                                 >
        //                                     IVA: 
        //                                     <Typography 
        //                                         variant = "body2"
        //                                         sx = { typographyCSS.subTypography }
        //                                     >
        //                                         ${ data.iva }
        //                                     </Typography>
        //                                 </Typography>
        //                             </CardContent>
        //                         </Card>
        //                     </Box>
        //                 </Grid>
        //                 <Grid
        //                     item
        //                     xs = { 6 }
        //                 >
        //                     <Button
        //                         sx = {{ textTransform : 'none' }}
        //                         variant = "contained"
        //                         onClick = { sendInformationAndGeneratePDF }
        //                     >
        //                         Confirmar
        //                     </Button>
        //                 </Grid>
        //             </Grid>
        //         </CardContent>
        //     </Card>
        // </Box>

        <>
            <Button
                sx = {{ textTransform : 'none' }}
                variant = "contained"
                onClick = { sendInformationToDB }
                disabled = { waitAnResult }
            >
                Confirmar
            </Button>
        </>
    );
}