import React, {useState, useEffect} from 'react';
import {
        Container,
        Grid,
        Paper,
    } from '@mui/material';

import * as ReactDOM from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { CSVLink } from "react-csv";

import { DataGrid } from '@mui/x-data-grid';

import Copyright from '../../../General/Copyright';
import FormFilter from './formFilter';

function Contain(props){
    const [rows, setRows] =useState([]);
    const [params,setParams] = useState({});
    const [formValue, setFormValue] = useState(
            {
                "opMovement":0,
                "startDate":null,
                "endDate":(new Date()).toLocaleDateString()
            }
        );
    const envConfig = {
            url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
        };
    
    useEffect(()=>{
            setParams(new URLSearchParams(formValue));
        },[formValue]);

    
    const columns = [
            { field: 'guia', headerName: 'Guia', width: 150 },
            { field: 'proceso', headerName: 'Proceso', width: 130, valueGetter:(params)=> `${params.row.proceso.name || ''}` },
            { field: 'sucursal_id', headerName: 'Sucursal',type: 'number',width: 200, valueGetter:(params)=> `${params.row.sucursal.name || ''}` },
            { field: 'created_at', headerName: 'Fecha',type: 'number',width: 200, valueGetter:(params)=> (new Date(params.row.created_at)).toLocaleDateString()}
        ];

    const handleSubmit= async (e)=>{
        e.preventDefault();
        var element = (
            <CircularProgress color="success" />
        );
        ReactDOM.render(element, document.querySelector('#downloadLink'));

        try{
            const data = await fetch(envConfig.url+'/api/v1/reports/routing?'+params, {method: 'GET'})
                                .then((response)=>response.json());
            setRows(data["data"]);
        }
        catch(error){
            alert("Error de conexion, intente mas tarde")
            console.log(error);
        }
        try{
            const dataCsv = [
                ["Guia","Descripcion","Proceso","Sucursal","Fecha de creacion"]
            ];
            
            rows.forEach(element => {
                dataCsv.push([
                        element.guia,
                        element.descripcion,
                        element.proceso.name,
                        element.sucursal.name,
                        element.created_at,
                    ])
            });
            
            element = (<CSVLink 
                filename={String(Date.now())+"-Reporte.csv"}
                data={dataCsv}>Descargar</CSVLink>);
                
            ReactDOM.render(element, document.querySelector('#downloadLink'));
            
        }
        catch(error){
            console.log(error);
        }
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                    <FormFilter
                        formValue={formValue}
                        setFormValue={setFormValue}
                        submit={handleSubmit}
                    />
                    <div id="downloadLink"></div>
            </Grid>
            <Grid item sx={{
                width:"100%",
                display:'flex',
                flexDirection: 'row',
                justifyContent:'center',
                alignContent:'center'
            }}>
                <Paper sx={{ 
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    height: 400,
                    width:"100%"
                    }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            
                        />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default function ReportsIndex(props){
    return(
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Contain></Contain>
            <Grid container paddingTop={2}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                            p: 1,
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems:'center',
                            justifyContent:'center',
                            height: 50,
                        }}>
                        <Copyright sx={{ pt: 4 }} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
