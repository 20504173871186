import React , { useState } from 'react';
import {Grid ,
        TextField,
        FormControl,
        FormLabel,
        RadioGroup,
        FormControlLabel,
        Radio} from '@mui/material';

import './shippingStyles.css';
import ButtonLink from'./MarcoComponents/ButtonLink';

export default function ShippingInsurance(){

    const [ option , setOption ] = useState("No");
    const [ insuranceCost , setInsuranceCost ] = useState(0.0);
    const [ amount , setAmount ] = useState("0.00");
    const textFieldCSS = {
        backgroundColor : '#ffffff',
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
    };
    const radioGroupCSS = {
        marginLeft : '14px'
    };

    function yesOrNo( event ){
        setOption( event.target.value );
        console.log( option );
    }

    function calculatingInsuranceCost( event ){
        setAmount( event.target.value );
        if( event.target.value === '' ){
            setInsuranceCost( 0 );
            return;
        }
        if( isNaN( event.target.value ) ){
            setAmount("0.00");
            setInsuranceCost( 0 );
            return;
        }
        if( parseFloat( event.target.value ) < 1500.00 ){
            setInsuranceCost(( 1500.00 * .0125 * 1.16 ));
            return;
        }

        setInsuranceCost(( parseFloat( event.target.value ) * .0125 * 1.16 ));
    }

    function yesMenuOrNoMenu(){
        const gridPackageMeasurements = {
            backgroundColor : '#aba8a8',
            borderRadius : '4px',
            marginTop: '0',
            marginLeft : '-11px',
            paddingBottom : '23px',
            border : '2px solid #5b5b5b'
        };

        const gridItemPackageMeasurements = {
            marginLeft : '-12px'
        }

        if(option === 'No'){
            return(
                <Grid 
                    container 
                    spacing = {1}
                >
                    <Grid
                        item
                        xs = {6}
                    >
                        <ButtonLink 
                            url = "/shippingInfoList"
                            clases = ""
                            texto = "Regresar"
                        />
                    </Grid>
                    <Grid
                        item
                        xs = {6}
                    >
                        <ButtonLink 
                            url = "/methodsOfPayment"
                            clases = ""
                            texto = "Siguiente"
                        />
                    </Grid>
                </Grid>
            );
        }

        return(
            <Grid 
                container 
                spacing = {2}
                sx = { gridPackageMeasurements }
            >
                <Grid 
                    item 
                    xs = {6}
                    sx = { gridItemPackageMeasurements }
                >
                    <TextField 
                        id = "filled-number" 
                        label = "Monto"  
                        InputLabelProps = {{ shrink: true, }}
                        variant = "filled"
                        sx = { textFieldCSS }
                        onChange = { calculatingInsuranceCost }
                        value = { amount }
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {6}
                >
                    <TextField 
                        id = "filled-basic" 
                        label = "Costo del Seguro" 
                        variant = "filled"
                        sx = { textFieldCSS }
                        value = { insuranceCost }
                        disabled
                    />
                </Grid>
                <Grid 
                    item 
                    xs = {12}
                    sx = { gridItemPackageMeasurements }
                >
                    <Grid 
                        container 
                        spacing = {1}
                    >
                        <Grid
                            item
                            xs = {6}
                        >
                            <ButtonLink 
                                url = "/shippingInfoList"
                                clases = ""
                                texto = "Regresar"
                            />
                        </Grid>
                        <Grid
                            item
                            xs = {6}
                        >
                            <ButtonLink 
                                url = "/methodsOfPayment"
                                clases = ""
                                texto = "Siguiente"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return(
        <div className = "container">
            <form className = "formulario formulario--shippingInsurance">
                <fieldset className = "fieldsetMain">
                    <legend>Seguro de envio</legend>

                    <Grid container spacing = {2}>

                        <Grid
                            item
                            xs = {12}>
                            <FormControl
                                sx = {{
                                    backgroundColor : '#ffffff',
                                    padding : '5px',
                                    borderRadius : '5px',
                                    border: '2px solid #ff4500'
                                }}>
                                <FormLabel id="demo-row-radio-buttons-group-label">¿Quiere Seguro?</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby = "demo-row-radio-buttons-group-label"
                                    name = "row-radio-buttons-group"
                                    value = { option }
                                    onChange = { yesOrNo }
                                    sx = { radioGroupCSS }
                                >
                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs = {12}
                        >
                            {
                                yesMenuOrNoMenu()
                            }
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </div>
    );
}