import React from 'react'

import { Card, CardActionArea, CardActions, CardMedia, Grid, Typography, CardContent, Button, Box } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';

//Importamos las imagenes
import dhl from "../../../assets/logotiposProveedores/DHL.webp";
import estafeta from "../../../assets/logotiposProveedores/Estafeta.webp";
import fedex from "../../../assets/logotiposProveedores/FedEx.webp";
import rabee from "../../../assets/logotiposProveedores/Rabee.webp";

function ListarCotizaciones() {
    
    //Navegacion
    const navigation = useNavigate();
    const location = useLocation();
    const cotizaciones = location.state.data.cotizaciones;
    const detalles = location.state.data.detalles;
    const object_JSON = require( '../GuiasAdmin/Shipping/originAndDestinationData.json');


    //Personalización de tema
    const color_tema = "#ff4500";

    function seleccionarTema (dia, arr) {
        for(let item of arr){
            if(item === dia){
                return 2;
            }
        }
        return 0;
    }

    //Componente de cada una de las cotizaciones
    const Item = (props) => {
        
        const {servicio_nombre, proveedor,costo, fecha_entrega_estimada, dias_de_entrega} = props.cotizacion;
        const fecha = new Date(fecha_entrega_estimada);
        
        return(
            <Grid item>
                <Card sx={{minWidth: 600, maxWidth: 600, maxHeight: 250}}>
                    <CardActionArea onClick={()=>{
                        // alert(JSON.stringify(props.cotizacion));
                        object_JSON.data.origin.origenCP = detalles.origen;
                        object_JSON.data.destination.destinoCP = detalles.destino;
                        object_JSON.data.kilosServiceAndNumber.kg = detalles.peso;
                        object_JSON.data.kilosServiceAndNumber.alto = detalles.alto;
                        object_JSON.data.kilosServiceAndNumber.ancho = detalles.ancho;
                        object_JSON.data.kilosServiceAndNumber.largo = detalles.largo;
                        object_JSON.data.kilosServiceAndNumber.tipo_servicio_id = props.cotizacion.servicio_id;
                        object_JSON.data.whoIsPaying.montoACobrar = props.cotizacion.costo;
                        object_JSON.data.isQuote = true;
                        navigation("/originOrDestination");
                    }}>
                        <CardContent sx={{padding: "0"}}>
                            <Grid container direction="row" rowSpacing={0} alignItems="flex-start" justifyContent="flex-start">
                                <Grid item xs={6}>
                                    {
                                        {
                                            'dhl': <img style={{height: "250px", width: "300px"}} src={dhl}/>,
                                            'estafeta': <img style={{height: "250px", width: "300px"}} src={estafeta}/>,
                                            'fedex': <img style={{height: "250px", width: "300px"}} src={fedex}/>,
                                            'rabee': <img style={{height: "250px", width: "300px"}} src={rabee}/>
                                        }[proveedor.toLowerCase()]
                                    }
                                </Grid>
                                <Grid item padding={1} xs={6}>
                                    <Grid container direction="column" sx={{minHeight: 225}} justifyContent="space-between" alignContent="stretch">
                                        <Grid item>
                                            <Grid container justifyContent="space-between">
                                                <Grid item>
                                                    <Box border={seleccionarTema("Lunes", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>L</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box border={seleccionarTema("Martes", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>M</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box border={seleccionarTema("Miércoles", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>M</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box border={seleccionarTema("Jueves", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>J</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box border={seleccionarTema("Viernes", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>V</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box border={seleccionarTema("Sábado", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>S</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box border={seleccionarTema("Domingo", dias_de_entrega)} borderColor={color_tema} borderRadius="50%">
                                                        <Typography  variant='body1' paddingLeft={1} paddingRight={1}>D</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item >
                                            <Typography variant="h6" fontWeight="bold">
                                                {servicio_nombre}
                                            </Typography>
                                        </Grid>

                                        <Grid item >
                                            <Typography variant="h6" fontWeight="light">
                                                ${costo}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Box border={2} borderColor={color_tema} borderRadius={1}>
                                                <Typography variant="h6" fontWeight="bold" backgroundColor={color_tema} color="white" >
                                                    Fecha de entrega
                                                </Typography>
                                                <Typography variant="h6" fontWeight="light" sx={{color: {color_tema}}}>
                                                    {fecha.getDate()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent="center" alignItems="center" direction="column" rowSpacing={5} sx={{paddingY: 5}}>
            {
                cotizaciones.length > 0 ? (
                    cotizaciones.map((cotizacion)=>(
                        <Item cotizacion={cotizacion}/>
                    ))
                    ) : (
                        <Typography variant="h6">No hay empresas registradas</Typography>
                )
            }
        </Grid>
  )
}

export default ListarCotizaciones