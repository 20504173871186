import React from 'react';
import {
    Home,
    Send,
} from '@mui/icons-material';
import { 
    Container,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import './shippingStyles.css';
import ButtonLink from './MarcoComponents/ButtonLink';
import GridListOriginOrDestination from './MarcoComponents/GridListOriginOrDestination';
import { objectJSON } from '../Shipping/MarcoComponents/FormModel';

export default function OriginOrDestination(){
    const data = require("./originOrDestination.json");
    const { originOrDestination } = data;
    const buttonCSS = [
        {
            textTransform : 'none',
            backgroundColor : '#053ecc',
            border : '2px solid #3466e0',
            '&:hover': {
                backgroundColor: '#0835a5',
                border : '2px solid #274ca8',
            }
        },
        {
            textTransform : 'none',
            backgroundColor : '#5406ab',
            border : '2px solid #853fd5',
            '&:hover': {
                backgroundColor: '#410883',
                border : '2px solid #5f3093',
            }
        }
    ];
    const icons = [
        <Home />,
        <Send />,
    ];

    return(

        <Container maxWidth="lg">
            
            <Paper elevation={3} sx={ { marginY: 5, paddingX: 5, paddingY: 5} }>

                {/* Encabezado de la página */}
                <Container sx={ { textAlign: "left"} }>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Typography variant='h5'>
                                Crear guia
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <ButtonLink 
                                url = { objectJSON.data.isQuote ? "/quotes" : "/waybillsTable" }
                                clases = "containerboton" 
                                texto = "Regresar"
                            />
                        </Grid>
                    </Grid>
                </Container>


                <Container sx={ { textAlign: "left"} }>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GridListOriginOrDestination arrayObjects = { originOrDestination } arrayButtonsCSS = { buttonCSS } arrayIcons = { icons } divGridClasses = "containerGrid containerGrid--originOrDestination"/>
                        </Grid>
                    </Grid>
                </Container>
                        
                    
            </Paper>
        </Container>
    )
}

/*

<div className = "container container--methodsOfPayment">
            
            

            

        </div>
        */