import React from 'react';
import {Button,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle} from '@mui/material';

export default function ConfirmationDialog(props){
    const {
        open,
        handleClose,
        waybillNumber
    } = props;

    return(<Dialog
                onBackdropClick = "false"
                open = {open}
                onClose = {handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id = "alert-dialog-title"
                >
                    {"Cancelar Guia"}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText
                        id = "alert-dialog-description"
                    >
                        ¿Estas seguro en cancelar la Guia { waybillNumber }? 
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick = { handleClose }
                    >
                        Cancelar
                    </Button>

                    <Button
                        onClick = { handleClose }
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        );
}