import React, {useState, useEffect} from 'react';
import * as ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { CSVLink, CSVDownload } from "react-csv";

import GuiasFormAdd from './form_add';
import GuiasFormAddGroup from './form_add_group';
import FormLocation from './form_select_location'
import ReceivedWaybills from './resume'

import {
    Button
} from '@mui/material';

import Copyright from '../../../General/Copyright';

import HorizontalLinearStepper from '../../../General/HorizontalStepper'

export default function GuiasIndex(props) {
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );
    const [listItems, setListItems] = useState([]);
    const [waybills, setWaybills] = useState(0);
    const {locationId} = props
    const [lugares, setLugares] = useState([]);
    const [procesos, setProcesos] = useState([]);
    const [formValue, setFormValue] = useState({
        "place":0,
        "status":0
    });

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };
    
    const [dataToSend, setDataToSend] = useState(
        {
            "user_id": userData.id,
            "sucursal_id":formValue['place'],
            "rastreo_proceso_id":formValue['status'],
            "tipo_servicio_id":1,
            "kg_amparados":1,
            "waybills":listItems
        });
    
    useEffect(()=>{
        setDataToSend(
            {
                "user_id": userData.id,
                "sucursal_id":formValue['place'],
                "rastreo_proceso_id":formValue['status'],
                "tipo_servicio_id":1,
                "kg_amparados":1,
                "waybills":listItems
            }
        );
    },[formValue,listItems,locationId]);
    useEffect(()=>{
        const localItems = JSON.parse(localStorage.getItem("dataToSend"));
        if(localItems!==null){
            console.log();
            setDataToSend(
                localItems
            );
            
            setListItems(localItems["waybills"])
            setFormValue({"place":localItems["sucursal_id"],"status":localItems["rastreo_proceso_id"]})
        }
    },[]);
    
    useEffect(()=>{
        localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    },[dataToSend]);
    
    const sendInfo = async (e)=>{
        e.preventDefault();
        //Export
        var element = (
            <CircularProgress color="success" />
        );
        ReactDOM.render(element, document.querySelector('#popUp'));
        const dataCsv = [
            ["Guias"]
        ];

        listItems.forEach(element => {
            dataCsv.push([element])
        });

        try{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataToSend)
            }; 
            console.log(requestOptions);
            const response = await fetch(envConfig.url+'/api/v1/routing/add', requestOptions);
            const data = await response.json();

            alert("Subido exitoso!");

            element = (<CSVLink 
                            filename={String(Date.now())+"-Recibidas.csv"}
                            data={dataCsv}>Descargar</CSVLink>);

            ReactDOM.render(element, document.querySelector('#popUp'));
        }
        catch(error){
            console.log(error);
            alert("Error al subir, descargar archivo y mandar, antes de limpiar")

            element = (<CSVLink 
                            filename={String(Date.now())+"-Recibidas.csv"}
                            data={dataCsv}>Descargar</CSVLink>);
            ReactDOM.render(element, document.querySelector('#popUp'));
        }

    }
    const cleanInfo=()=>{
        //Reset
        localStorage.setItem('dataToSend',null);
        window.location.reload();
    }
    const cleanInfoAlert=()=>{
        if (window.confirm("Esta accion no se puede deshacer, ¿Seguro que quieres limpiar?")) {
            cleanInfo()
        }
    }
    const ListForm=(props)=>{
        const {formValue}=props;
        const Title =()=>{
            return(((formValue['place']!==0) || (formValue['status']!==0))?
                lugares[formValue['place']-1]['name']+": "+
                procesos[formValue['status']-1]['name']:
                <p>Falta seleccionar Lugar y/o Proceso</p>
            );
        }
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 2, 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: 100,
                        }}>
                            <Title/>
                        <div id="popUp"></div>
                        
                    </Paper>
                </Grid>
                    {/* Chart */}
                <Grid item xs={12} md={4} lg={5}>
                    <Paper
                        sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 400,
                        }}
                    >
                        <GuiasFormAdd 
                            listItems = {listItems}
                            setListItems={setListItems}
                            
                            waybills={waybills}
                            setWaybills={setWaybills}
                            
                            dataToSend={dataToSend}
                            />
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={8} lg={7}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                            p: 2,
                            mb:2,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 80,
                            flexWrap:"wrap",
                            justifyContent: 'center',
                            alignItems:'center'
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h6"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                    Escaneadas:{waybills}
                            </Typography>
                            <Typography
                                component="h3"
                                variant="h6"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                    Exito:{listItems.length}
                            </Typography>
                            {((formValue['place']!==0) || (formValue['status']!==0))?
                            <Button 
                                variant="contained" 
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 40,
                                    width:"20%",
                                    }}
                                onClick={sendInfo}
                                >
                                    Subir
                            </Button>
                            :""
                            }
                            <Button 
                                variant="secondary" 
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 40,
                                    width:"20%",
                                    }}
                                onClick={cleanInfoAlert}
                                >
                                    Limpiar
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 300,
                            }}
                        >
                            <GuiasFormAddGroup
                                listItems = {listItems}
                                setListItems={setListItems}
                                />
                        </Paper>
                    </Grid>
                </Grid>
                
            </Grid>
        )}
        
    return(
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <HorizontalLinearStepper 
                    titles={["Lugar Accion","Escanear"]} 
                    steps={[
                            <FormLocation
                                setLugares = {setLugares}
                                lugares = {lugares}
                                setProcesos = {setProcesos}
                                procesos = {procesos}
                                formValue = {formValue}
                                setFormValue = {setFormValue}
                            />,
                            <ListForm formValue ={formValue}/>,
                            <ReceivedWaybills/>,
                        ]}
                    finalMessage="Pasos Completados, presione Reiniciar"
                    />
            <Grid container paddingTop={2}>
                <Grid item xs={12}>
                    <Paper sx={{ 
                            p: 1,
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems:'center',
                            justifyContent:'center',
                            height: 50,
                        }}>
                        <Copyright sx={{ pt: 4 }} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
    
}
