import React , { useState , useEffect } from 'react';
import {
    Grid,
    Button,
    Modal,
    TextField,
    Container,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Alert,
    AlertTitle
} from '@mui/material';
import {
    Close,
    SentimentVeryDissatisfied
} from '@mui/icons-material';

export default function AddressModal(props){
    const {
        open,
        handleClose,
        selectingAndLoadingAnAddress
    } = props;

    /* Estados para el modal de direcciones */
    const [ arrayAddress , setArrayAddress ] = useState([]);
    const [ addressRows , setAddressRows ] = useState([]);
    const [ address , setAddress ] = useState('');

    /* Objeto del usuario que ingreso al programa */
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    /* Objeto de configuración de enviroment */
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    };

    /* Objeto de estilos para el modal de direcciones */
    const styles = {
        margin: '120px auto 0 auto',
        width: '72%',
        height: '65%',
        border: '2px solid #efeff0',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3
    };

    /* Mapeos */

    //Generando las columnas de la tabla por medio de un array
    const columns = [
        'Nombre',
        'Domicilio',
        'Telefono principal',
        'Telefono alternativo',
        'Empresa'
    ];

    const columnsMap = columns.map( column => (
        <TableCell sx={{ 'textAlign' : 'center' }}>
            {column}
        </TableCell>
    ) );

    //Generando los renglones de la tabla por medio de la lista de direcciones
    const generateRowsToAddressTable = ( array , isSearchingAnAddress=false ) => {

        /*¿Se hara alguna filtración en nuestra tabla?*/
        if( isSearchingAnAddress ){
            /*Si es así, se realizará la filtración en nuestra tabla*/
            const addressToShow = array.map( element => {
                if( element['nombre'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    element['apellidoPaterno'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    (element['apellidoMaterno']!==null && element['apellidoMaterno'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1) || 
                    element['telefono1'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    (element['telefono2']!==null && element['telefono2'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1) || 
                    element['calle'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    element['numeroExterior'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 || 
                    (element['numeroInterior']!==null && element['numeroInterior'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1) ||
                    element['colonia'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 || 
                    element['municipio'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    element['estado'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 || 
                    element['pais'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 ||
                    element['codigoPostal'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1 || 
                    (element['empresa']!==null && element['empresa'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,'')
                    .search(address.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g,'')) !== -1) 
                ){
                    return(
                        <TableRow
                            onClick={()=>{
                                selectingAndLoadingAnAddress(element);
                                handleClose();
                            }}
                            sx={{
                                transition: "transform .2s",
                                "&:hover":{
                                    cursor: "pointer",
                                    boxShadow: 15,
                                    transform: "scale(1.04)"
                                }
                            }}
                        >
                            <TableCell sx={{ 'textAlign' : 'center' }}>
                                {element.nombre} {element.apellidoPaterno} {element.apellidoMaterno}
                            </TableCell>
                            <TableCell>
                                {element.calle}
                                { (element.numeroInterior) ? ` #${element.numeroExterior} - ${element.numeroInterior} \n` : ` #${element.numeroExterior} \n` }
    
                                <br></br>
    
                                {element.colonia}, {element.municipio} {element.codigoPostal}
    
                                <br></br>
                                {element.estado}, {element.pais}
                            </TableCell>
                            <TableCell sx={{ 'textAlign' : 'center' }}>
                                {element.telefono1}
                            </TableCell>
                            <TableCell sx={{ 'textAlign' : 'center' }}>
                                { (element.telefono2) ? element.telefono2 : '**' }
                            </TableCell>
                            <TableCell sx={{ 'textAlign' : 'center' }}>
                                { (element.empresa) ? element.empresa : '**' }
                            </TableCell>
                        </TableRow>
                    );
                }
            } );
            
            /*Sí no hubo algun resultado, entonces retornaremos un array vacío*/
            if( addressToShow.length === 3 && 
                addressToShow[0] === undefined && 
                addressToShow[1] === undefined &&
                addressToShow[2] === undefined ){
                return [];
            }
    
            /*Sí hubo algun resultado, entonces retornamos nuestros resultados obtenidos*/
            return addressToShow;
        }

        /*Carga toda la información del arreglo principal.*/
        const addressToShow = array.map( address => (
            <TableRow
                onClick={()=>{
                    selectingAndLoadingAnAddress(address);
                    handleClose();
                }}
                sx={{
                    transition: "transform .2s",
                    "&:hover":{
                        cursor: "pointer",
                        boxShadow: 15,
                        transform: "scale(1.04)"
                    }
                }}
            >
                <TableCell sx={{ 'textAlign' : 'center' }}>
                    {address.nombre} {address.apellidoPaterno} {address.apellidoMaterno}
                </TableCell>
                <TableCell>
                    {address.calle}
                    { (address.numeroInterior) ? ` #${address.numeroExterior} - ${address.numeroInterior} \n` : ` #${address.numeroExterior} \n` }

                    <br></br>

                    {address.colonia}, {address.municipio} {address.codigoPostal}

                    <br></br>
                    {address.estado}, {address.pais}
                </TableCell>
                <TableCell sx={{ 'textAlign' : 'center' }}>
                    {address.telefono1}
                </TableCell>
                <TableCell sx={{ 'textAlign' : 'center' }}>
                    { (address.telefono2) ? address.telefono2 : '**' }
                </TableCell>
                <TableCell sx={{ 'textAlign' : 'center' }}>
                    { (address.empresa) ? address.empresa : '**' }
                </TableCell>
            </TableRow>
        ) );

        /*Retornamos toda la información cargada en el mapeo*/
        return addressToShow;
    }

    /* Función catch para el textfield de direccion */
    const catchAddress = event => setAddress( event.target.value ); 

    /* Funcion para realizar la petición HTTP */
    const getAddresses = async () => {
        const URL = envConfig.url + '/api/v1/adminDirecciones/get';

        const addressData = {
            data: {
                user_id: userData.id,
            }
        }

        await fetch( URL , {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify( addressData )
        } )
            .then( res => {
                if(!res.ok){
                    alert( 'Algo ocurrio :C : ' + res.status  );
                    return;
                }

                return res.json();
            } )
            .then( data => {
                setArrayAddress( data.data );
                setAddressRows( generateRowsToAddressTable( arrayAddress ) );
            } )
            .catch( error => alert( 'Hubo un problema : ' + error ) );
    }

    /* useEffect para cargar nuestra tabla de direcciones */
    useEffect( () => getAddresses() , [ open ] );
    useEffect( () => { 
        if( arrayAddress.length !== 0 ){
            if( address !== '' ){
                setAddressRows( generateRowsToAddressTable( arrayAddress , true ) );
                return;
            }

            setAddressRows( generateRowsToAddressTable( arrayAddress ) );
        }
    } , [ address ] );

    /* Retornando el modal de direcciones */
    return(
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="parent-modal-title">
            <Container maxWidth="lg">
                <Paper elevation={10} sx={styles}>
                    {/* Titulo del modal + el boton de cerrar */}
                    <Grid 
                        container 
                        justifyContent="space-between" 
                        spacing={2}
                    >
                        {/* Titulo */}
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5">
                                Buscar y cargar dirección
                            </Typography>
                        </Grid>

                        {/* Boton para cerrar el modal de direcciones */}
                        <Grid item xs={12} md={3}>
                            <div align="right">
                                <IconButton 
                                    onClick={ () => {
                                        setAddress('');
                                        setAddressRows([]);
                                        setArrayAddress([]);
                                        // setArrayToAddressTable([]);
                                        handleClose(); 
                                    }}
                                    sx={{
                                        backgroundColor : "#e10000",
                                        color : "#ffffff",
                                        width : "35px",
                                        height : "35px",
                                        borderRadius: 2,
                                        border: "2px solid #e10000",
                                        "&:hover" : {
                                            backgroundColor : "#b40000",
                                            color : "#dcdcdc",
                                            border: "2px solid #b40000",

                                        }
                                    }}
                                    title="Cerrar"
                                >
                                    <Close fontSize="small"/>
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid 
                        container 
                        spacing={2} 
                        xs={{ maxHeight : 20 }}
                        sx={{ marginTop : 1 }}
                    >
                        {/* Textfield para introducir información para buscar la dirección deseada */}
                        <Grid item xs={12}>
                            <TextField
                                id="inputDireccion"
                                label="Localiza la dirección deseada"
                                placeholder="Av. 16 de Septiembre Guadalajara..."
                                fullWidth={true}
                                margin="none"
                                value={address}
                                onChange={catchAddress}
                            />
                        </Grid>
                    </Grid>

                    {
                        ( addressRows.length === 0 ) ? (
                            // <div style={{ 
                            //     marginTop: "18px",
                            //     color: "#b40000",
                            //     display: "flex",
                            //     justifyContent: "center",
                            //     alignItems: "center"
                            // }}>
                            //     <Typography>
                            //         Sin resultados, verifique la información que ingreso
                            //     </Typography>
                            //     <SentimentVeryDissatisfied color="error" fontSize="small"/>
                            // </div>

                            <Alert 
                                severity="error"
                                sx={{marginTop: "18px"}}
                            >
                                <AlertTitle>Dirección(es) no encontrada(s)!</AlertTitle>
                                <div 
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography>
                                        Sin resultados, verifique la información que ingreso
                                    </Typography>
                                    <SentimentVeryDissatisfied color="error" fontSize="small"/>
                                </div>
                            </Alert>
                        ) : (
                            <TableContainer sx={{ maxHeight : 310 , marginTop : 2 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {/* Encabezado de la tabla */}
                                    <TableHead>
                                        <TableRow>
                                            {columnsMap}
                                        </TableRow>
                                    </TableHead>
                                    {/* Contenido de la tabla */}
                                    <TableBody>
                                        {addressRows}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    }
                </Paper>
            </Container>
        </Modal>
    );
}