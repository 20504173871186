import React , { useState , useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {Button,
        Card,
        Box,
        CardContent,
        CardActions,
        TextField,
        Grid,
        FormGroup,
        FormControlLabel,
        Checkbox,
        FormControl,
        FormHelperText,
        InputLabel,
        FilledInput,
        InputAdornment,
        IconButton,
        Typography,
        Container,
        OutlinedInput,
        Paper,
        CircularProgress} from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';

import {Visibility,
        VisibilityOff,
        Key,
        AccountCircle} from '@mui/icons-material'        

import LogisticaReyesLogo from '../../../assets/LogisticaReyesLogo.jpeg';
import '../GuiasAdmin/Shipping/shippingStyles.css';

// export let flag = false;
// export function changeFlag(){
//     flag = !flag;
//     console.log(flag);
// }

export default function LogInIndex() {
    const navigate = useNavigate();

    const [ email , setEmail ] = useState( '' );
    const [ phone , setPhone ] = useState( '' );
    const [ isEmail , setIsEmail ] = useState( true );
    const [ password , setPassword ] = useState( '' );
    const [ showPassword , setShowPassword ] = useState( false );
    const [ waitAnResult , setWaitAnResult ] = useState( false );
    const [ loading , setLoading ] = useState( false );
    const [ errors , setErrors ] = useState({
        emailError : {
            status : false,
            message : ''
        },
        passwordError : {
            status : false,
            message : ''
        },
        userNotFoundError : {
            status : false,
            message : ''
        }
    });

    const textFieldCSS = {
        backgroundColor : '#ffffff',
        borderTopLeftRadius : '5px',
        borderTopRightRadius : '5px',
        width : '97%',
        marginTop : '12px',
        marginBottom : '-27px'
    };

    const gridButtonLogInCSS = ( theme ) => ({
        display : 'flex',
        justifyContent : 'end',
        marginBottom : '12px',
        marginRight : '13px',
        [ theme.breakpoints.between( 'xs' , 'sm' ) ] : {
            justifyContent : 'center',
            margin : '0 auto 12px auto'
        }
    })

    const buttonLogInCSS = {
        backgroundColor : '#ff4500',
        '&:hover': {
            backgroundColor: '#e93f01'
        },
    }

    const checkboxCSS = {
        marginLeft : '4px'
    }

    function goTo(){
        navigate('/dashboard');
    }

    const catchEmailOrPhone = event => {
        if( isNaN( event.target.value ) ){
            setIsEmail( true );
            setEmail( event.target.value );
            setPhone( '' );
            return;
        }

        setIsEmail( false );
        setEmail( '' );
        setPhone( event.target.value );
    }
    const catchPassword = event => setPassword( event.target.value );
    const handleClickShowPassword = () => setShowPassword( !showPassword );
    const handleMouseDownPassword = event => event.preventDefault();

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };

    const logIn = async ( event ) => {
        setWaitAnResult( true );
        setLoading( true );
        event.preventDefault();
        const URL = envConfig.url+`/api/logIn`;

        const logInData = {
            phone : phone,
            email : email,
            password : password,
        }

        await fetch( URL , {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify( logInData )
        } )
            .then( res => res.json() )
            .then( data => {
                switch( data.data.status ){
                    case 404: 
                        setErrors({
                            emailError : {
                                status : false,
                                message : ''
                            },
                            passwordError : {
                                status : false,
                                message : ''
                            },
                            userNotFoundError : {
                                status : true,
                                message : data.data.error
                            }
                        });
                    break;
                    case 422:
                        if( email === '' && phone === '' && password === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[1]
                                },
                                passwordError : {
                                    status : true,
                                    message : data.data.error.password
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( email === '' && password !== '' && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[1]
                                },
                                passwordError : {
                                    status : false,
                                    message : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( password === '' && email !== '' && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : false,
                                    message : ''
                                },
                                passwordError : {
                                    status : true,
                                    message : data.data.error.password
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( phone.length !== 10 && ( password === '' || password !== '' ) && email === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.phone[0]
                                },
                                passwordError : {
                                    status : ( password === '' ) ? true : false,
                                    message : ( password === '' ) ? data.data.error.password : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }

                        if( !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
                            && ( password === '' || password !== '' ) && phone === '' ){
                            setErrors({
                                emailError : {
                                    status : true,
                                    message : data.data.error.email[0]
                                },
                                passwordError : {
                                    status : ( password === '' ) ? true : false,
                                    message : ( password === '' ) ? data.data.error.password : ''
                                },
                                userNotFoundError : {
                                    status : false,
                                    message : ''
                                }
                            });
                            return;
                        }
                    break;
                    case 200:
                        setErrors({
                            emailError : {
                                status : false,
                                message : ''
                            },
                            passwordError : {
                                status : false,
                                message : ''
                            },
                            userNotFoundError : {
                                status : false,
                                message : ''
                            }
                        });

                        sessionStorage.setItem( 'token' , data.data.token );
                        const token = sessionStorage.getItem( 'token' );
                        sendUserToken( token );
                    break;
                }
            } )
            .catch( error => alert( `Algo ocurrio :C : ${ JSON.stringify( error ) }` ) );
            setLoading( false );
            setWaitAnResult( false );
    }

    const sendUserToken = async ( token ) => {
        const URL = envConfig.url+`/api/profile-details`;
        
        await fetch( URL , {
            method : 'GET',
            headers : {
                'Authorization' : `Bearer ${ token }`,
                'Content-Type' : `application/json`
            }
        } )
            .then( res => res.json() )
            .then( data => {
                    sessionStorage.setItem( 'user' , JSON.stringify( data.data ) );
                    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );
                    getRolePermissions( userData.role_id );
                } )
            .catch( error => alert(`error : ${ JSON.stringify( error ) }`) );
    }

    const getRolePermissions = async ( role_id ) => {
        const URL = envConfig.url+`/api/v1/permissions/get_permissions`;

        const roleObject = {
            data : {
                role_id : role_id
            }
        }
        console.log( roleObject );

        await fetch( URL , {
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify( roleObject )
        } )
            .then( res => res.json() )
            .then( data => {
                sessionStorage.setItem( 'permissions' , JSON.stringify( data.data ) );
                window.location.reload();
            } )
            .catch( error => alert(`error :C: ${ JSON.stringify( error ) }`) );
    }

    return (
        <div style = {{
            backgroundColor : '#f7f7f7',
            paddingTop : '10px',
            paddingBottom : '400px',
        }}>
            <Container maxWidth = "sm">
                <div 
                    align = "center"
                    style = {{ marginTop : "100px" }}
                >
                    <Box 
                        component = "img"
                        sx = { ( theme ) => ({
                            width : '95px',
                            height : '95px',
                            border : '2px solid #000000',
                            borderRadius : '5px'
                        }) }
                        src = { LogisticaReyesLogo }
                        alt = "LogisticaReyesIcono"
                    />
                </div>
            </Container>
            <Container maxWidth = "sm">
                <Paper elevation = {3} sx = {{ marginY: 3 , paddingX: 5 , paddingY: 5 }}>
                    <form onSubmit = { logIn }>
                        <Container sx = {{ textAlign : "center" }}>
                            <Grid container spacing = {5}>
                                <Grid item xs = { 12 } md = { 12 }>
                                    <TextField
                                        error = { errors.emailError.status }
                                        id = "outlined-text" 
                                        label = "Ingrese su correo electronico o telefono" 
                                        variant = "outlined"
                                        onChange = { catchEmailOrPhone }
                                        value = { isEmail ? email : phone }
                                        helperText = { errors.emailError.message }
                                        InputProps = {{
                                            startAdornment: (
                                                <InputAdornment position = "start">
                                                    <AccountCircle sx = {{ color : "#ff4500" }}/>
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth = { true }
                                    />
                                </Grid>

                                <Grid item xs = {12} md = {12}>
                                    <FormControl 
                                        error = { errors.passwordError.status }  
                                        variant="outlined"
                                        fullWidth = { true }
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={ showPassword ? 'text' : 'password'}
                                            value={ password } 
                                            onChange={ catchPassword }
                                            startAdornment={
                                                <InputAdornment position = "start" >
                                                    <Key sx = {{ color : "#ff4500" }}/>
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    { showPassword ? <VisibilityOff sx={{ color : "#ff4500" }}/> : <Visibility sx={{ color : "#ff4500" }}/>}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            label = "Contraseña"
                                        />
                                        <FormHelperText>{ errors.passwordError.message }</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs = {12} md = {5}>
                                    <div align = "left">
                                        <Button
                                            variant = "contained"
                                            sx = { buttonLogInCSS }
                                            type = "submit"
                                            disabled = { waitAnResult }
                                        >
                                            Ingresar
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item xs = {12} md = {7}>
                                    {
                                        ( loading ) ? <>
                                            <div
                                                style = {{
                                                    display : "flex",
                                                    alignItems : "center",
                                                    gap : "10px"
                                                }}
                                            >
                                                <Box>
                                                    <CircularProgress
                                                        variant = "indeterminate"
                                                        disableShrink
                                                        size = {40}
                                                        sx = {{
                                                            color : "#ff4500",
                                                            animationDuration : "550ms",
                                                            [`& .${circularProgressClasses.circle}`] : {
                                                                strokeLinecap : "round"
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant = "body2"
                                                    fontWeight = "bold"
                                                >
                                                    Procesando, espere un momento...
                                                </Typography>
                                            </div>
                                        </> : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Container>

                        <Container sx = {{ textAlign : "center" }}>
                            {
                                ( errors.userNotFoundError.status ) ? 
                                        <h5
                                            style = {{
                                                marginTop : "12px",
                                                marginBottom : "-20px",
                                                color : "#b40000"
                                            }}
                                        >
                                            { errors.userNotFoundError.message }
                                        </h5>
                                    :
                                        <></>
                            }
                        </Container>
                    </form>
                </Paper>
            </Container>
        </div>
    );
}

//<Card
//                    sx = {( theme ) => ({
//                        margin : '120px auto 0 auto',
//                        width : '35%',
//                        [ theme.breakpoints.between( 'xs' , 'sm' ) ] : {
//                            width : '95%',
//                            marginTop : '230px'
//                        },
//                        [ theme.breakpoints.between( 'md' , 'lg' ) ] : {
//                            width : '44%'
//                        },
//                        [ theme.breakpoints.between( 'sm' , 'md' ) ] : {
//                            width : '54%'
//                        }
//                    })}
//                >
//                
//                <form onSubmit = { logIn }>
//                    <CardContent>
//                        <Grid
//                            container
//                            spacing = { 4 }
//                        >
//                            <Grid
//                                item
//                                xs = { 12 }
//                            >
//                                <Box
//                                    component = "div"
//                                    sx = { ( theme ) => ({
//                                        backgroundColor : '#ff4500',
//                                        display : 'flex',
//                                        alignItems : 'center',
//                                        justifyContent : 'space-evenly',
//                                        paddingTop : '17px',
//                                        paddingBottom : '17px',
//                                        borderRadius : '6px',
//                                        [ theme.breakpoints.between( 'xs' , 'sm' ) ] : {
//                                            justifyContent : 'space-around'
//                                        },
//                                        [ theme.breakpoints.between( 'sm' , 'md' ) ] : {
//                                            justifyConetnt : 'space-around'
//                                        }
//                                    })}
//                                >
//                                    <Box 
//                                        component = "img"
//                                        sx = { ( theme ) => ({
//                                            width : '95px',
//                                            height : '95px',
//                                            border : '2px solid #000000',
//                                            borderRadius : '5px',
//                                            [ theme.breakpoints.between( 'sm' , 'md' ) ] : {
//                                                width : '83px',
//                                                height : '83px'
//                                            }
//                                        }) }
//                                        src = { LogisticaReyesLogo }
//                                        alt = "LogisticaReyesIcono"
//                                    />
//                                    <Typography
//                                        variant = "h1"
//                                        component = "h1"
//                                        sx = {( theme ) => ({
//                                            marginLeft : '-40px',
//                                            fontFamily : 'Arial Narrow, sans-serif',
//                                            color : '#ffffff',
//                                            fontSize : '32px',
//                                            fontWeight : '700',
//                                            [ theme.breakpoints.between( 'xs' , 'sm' ) ] : {
//                                                fontSize : '25px'
//                                            },
//                                            [ theme.breakpoints.between( 'sm' , 'md' ) ] : {
//                                                fontSize : '23px',
//                                                marginLeft: '-33px'
//                                            }
//                                        })}
//                                    >
//                                        Logistica Reyes
//                                    </Typography>
//                                </Box>
//                            </Grid>
//
//                            <Grid
//                                item
//                                xs = { 12 }
//                            >
//                                <TextField
//                                    error = { errors.emailError.status }
//                                    id = "outlined-text" 
//                                    label = "Ingrese su correo electronico o telefono" 
//                                    variant = "outlined"
//                                    sx = { textFieldCSS }
//                                    onChange = { catchEmailOrPhone }
//                                    value = { isEmail ? email : phone }
//                                    helperText = { errors.emailError.message }
//                                    InputProps = {{
//                                        startAdornment: (
//                                            <InputAdornment position = "start">
//                                                <AccountCircle sx = {{ color : "#ff4500" }}/>
//                                            </InputAdornment>
//                                        )
//                                    }}
//                                    fullWidth = { true }
//                                    required = { true }
//                                />
//                            </Grid>
//
//                            <Grid
//                                item
//                                xs = { 12 }
//                            >
//                                {/* <TextField 
//                                    id = "filled-number" 
//                                    label = "Contraseña" 
//                                    variant = "filled"
//                                    sx = { textFieldCSS }
//                                /> */}
//
//                                <FormControl 
//                                    error = { errors.passwordError.status }  
//                                    sx = { textFieldCSS }
//                                    variant="outlined"
//                                >
//                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
//                                    <OutlinedInput
//                                        id="outlined-adornment-password"
//                                        type={ showPassword ? 'text' : 'password'}
//                                        value={ password } 
//                                        onChange={ catchPassword }
//                                        startAdornment={
//                                            <InputAdornment position = "start" >
//                                                <Key sx = {{ color : "#ff4500" }}/>
//                                            </InputAdornment>
//                                        }
//                                        endAdornment={
//                                        <InputAdornment position="end">
//                                            <IconButton
//                                                aria-label="toggle password visibility"
//                                                onClick={handleClickShowPassword}
//                                                onMouseDown={handleMouseDownPassword}
//                                                edge="end"
//                                            >
//                                                { showPassword ? <VisibilityOff sx={{ color : "#ff4500" }}/> : <Visibility sx={{ color : "#ff4500" }}/>}
//                                            </IconButton>
//                                        </InputAdornment>
//                                        }
//                                        label = "Contraseña"
//                                        fullWidth = { true }
//                                        required = { true }
//                                    />
//                                    <FormHelperText>{ errors.passwordError.message }</FormHelperText>
//                                </FormControl>
//                            </Grid>
//
//                            {   
//                                ( errors.userNotFoundError.status ) ? 
//                                    <Grid
//                                        item
//                                        xs = { 12 }
//                                    >
//                                        <h5
//                                            style = {{
//                                                fontWeight : "normal",
//                                                color : "#b40000",
//                                                marginTop : "0px",
//                                                marginBottom : "-40px"
//                                            }}
//                                        >
//                                            { errors.userNotFoundError.message }
//                                        </h5>
//                                    </Grid>
//                                :
//                                    <></>
//                            }
//
//                            {/* <Grid
//                                item
//                                xs = { 12 }
//                                sx = { checkboxCSS }
//                            >
//                                <FormGroup
//                                    sx = {{
//                                        marginTop : "0px",
//                                        marginBottom : "0px"
//                                    }}
//                                >
//                                    <FormControlLabel control={<Checkbox />} label="Recordar usuario y contraseña." />
//                                </FormGroup>
//                            </Grid> */}
//                        </Grid>
//                    </CardContent>
//
//                    <CardActions
//                        disableSpacing
//                    >
//                        <Grid
//                            container
//                            spacing = { 1 }
//                        >
//                            <Grid
//                                item
//                                xs = { 12 }
//                                sx = { gridButtonLogInCSS }
//                            >
//                                {/* <Button
//                                    variant = "contained"
//                                    sx = { buttonLogInCSS }
//                                    onClick = { logIn }
//                                >
//                                    Ingresar
//                                </Button> */}
//                                <Button
//                                    variant = "contained"
//                                    sx = { buttonLogInCSS }
//                                    type = "submit"
//                                    disabled = { waitAnResult }
//                                >
//                                    Ingresar
//                                </Button>
//                            </Grid>
//                        </Grid>
//                    </CardActions>
//                </form>
//            </Card>