import * as React from 'react';
import {Link} from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LocalShipping from '@mui/icons-material/LocalShipping';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Assessment from '@mui/icons-material/Assessment';
import ContentPaste from '@mui/icons-material/ContentPaste';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Paid from '@mui/icons-material/Paid';

function setMainListItems() {
  const userPermissions = JSON.parse( sessionStorage.getItem( 'permissions' ) );

  const itemsList = [
    ( userPermissions !== null && userPermissions["reports-access"] !== undefined ) ?
      {
        url:"/",
        icon:<DashboardIcon />,
        title:"Dashboard"
      } : null,
    ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) ?
      {
        url:"/waybillsTable",
        icon:<ContentPaste />,
        title:"Guias"
      } : null,
    ( userPermissions !== null && userPermissions["sucursal-access"] !== undefined ) ?
      {
        url:"/addWaybills",
        icon:<QrCodeScannerIcon />,
        title:"Escanear Guias"
      } : null,
    ( userPermissions !== null && userPermissions["tracking-access"] !== undefined ) ?
      {
        url:"/tracking",
        icon:<LocalShipping />,
        title:"Rastreo"
      } : null,
    ( userPermissions !== null && userPermissions["reports-access"] !== undefined ) ?
      {
        url:"/reports",
        icon:<Assessment />,
        title:"Reportes"
      } : null,
    ( userPermissions !== null && userPermissions["address-access"] !== undefined ) ?
      {
        url:"/adminAddress",
        icon:<HomeWorkIcon />,
        title:"Administración de direcciones"
      } : null,
    ( userPermissions !== null && userPermissions["quotes-access"] !== undefined ) ?
      {
        url: "/quotes",
        icon: <Paid/>,
        title: "Cotizaciones"
      } : null
  ]
  return(
    itemsList.map((item, index)=>( item !==null ) ?
      <Link to={item.url} key={index}>
        <ListItemButton>
            <ListItemIcon
              title = { item.title }
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.title}/>
        </ListItemButton>
      </Link> : <></>
    )
  );
}

function setSecondaryListItems() {
  const itemsList = [
    {
      url:"/",
      icon:<AssignmentIcon />,
      title:"CurrentMonth"
    },
    {
      url:"/",
      icon:<AssignmentIcon />,
      title:"Last Quarter"
    },
    {
      url:"/",
      icon:<AssignmentIcon />,
      title:"Year-end sale"
    }
  ]
  return(
    itemsList.map((item, index)=>
    <ListItemButton key={index} component="a" href={item.url}>
      <ListItemIcon>
        {item.icon}
      </ListItemIcon>
      <ListItemText primary={item.title}/>
    </ListItemButton>
    )
  );
}

export const mainListItems = (
  <React.Fragment>
    {setMainListItems()}
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    {setSecondaryListItems()}
  </React.Fragment>
);
