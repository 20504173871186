import React, {useState, useEffect , useRef } from 'react';
import * as XLSX from 'xlsx';
// import { useCSVReader } from 'react-papaparse';
import {
    FormControl, 
    FormGroup, 
    Button, 
    Input,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Paper
} from '@mui/material';

// import CSVReaderInput from './MarcoComponents/CSVReaderInput';


export default function FormLocation(props){
    const {
        setLugares,
        lugares,
        setProcesos,
        procesos,
        formValue,
        setFormValue
    } = props;
    
    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==="prod")?process.env.REACT_APP_URL_PROD:process.env.REACT_APP_URL_DEV,
    };

    const buttonRef = useRef( null );
    // const { CSVReader } = useCSVReader();

    const getProcess = async ()=>{
        try{
            const data = await fetch(envConfig.url+'/api/v1/process/all?', {method: 'GET'})
                                    .then((response)=>response.json());
            setProcesos(data["data"]);
        }
        catch(error){
            console.log(error);
        }
    }
    const getPlaces = async ()=>{
        try{
            const data = await fetch(envConfig.url+'/api/v1/sucursales/all?', {method: 'GET'})
                                    .then((response)=>response.json());
            setLugares(data["data"]);
        }
        catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        getProcess();
        getPlaces();
    },[])

    const handleChange = (e) =>{
        e.preventDefault();
        setFormValue({...formValue, [e.target.name]:e.target.value});
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        alert(e.target.value);
    }
    // const handleFiles = ( files ) => {
    //     const reader = new FileReader();
    //     reader.onload = ( e ) => {
    //         alert( reader.result.name );
    //     }
    //     reader.readAsText( files[0] );
    // }

    const getExcelData = async ( file ) => {
        const excelFile = file;
        const data = await excelFile.arrayBuffer();
        const workbook = XLSX.read( data );
        const worksheet = workbook.Sheets[ workbook.SheetNames[0] ];
        const jsonObject = XLSX.utils.sheet_to_json( worksheet );

        console.log( jsonObject );
    }

    const getCSVData = ( file ) => {
        console.log( file );
    }

    const onErrorHandler = ( err, file, inputElem, reason ) => {
        console.log( err );
    }

    const handleFileRemove = ( file ) => {
        console.log( file );
    }

    const handleFiles = ( event ) => {
        // getExcelData( event.target.files[0] );
        getCSVData( event );
    }

    const sx = {
        p:2
    }
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 2, 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: 100,
                        }}>
                        <Typography
                                component="h3"
                                variant="h6"
                                color="#000000"
                                sx={{
                                    pr:3
                                }}
                                >
                                Lugar y Acción
                        </Typography>
                    </Paper>
            </Grid>
            <Grid item sx={{
                width:"100%",
                display:'flex',
                flexDirection: 'row',
                justifyContent:'center',
                alignContent:'center'
            }}>
                <Paper sx={{ 
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    height: 300,
                    width:"100%",
                    maxWidth: 500,
                    }}>
                    <FormGroup>
                        <FormControl fullWidth sx={sx}>
                            <InputLabel id="placeLabel">Lugar</InputLabel>
                            <Select
                                labelId="placeLabel"
                                id="place"
                                name="place"
                                label="place"
                                value={formValue["place"]}
                                onChange={handleChange}
                                >
                                <MenuItem name="place" value="0">Seleccione</MenuItem>
                                {lugares.map(
                                    (item)=>
                                    <MenuItem key={item.id} name="place" value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={sx}>
                            <InputLabel id="statusLabel">Estatus</InputLabel>
                            <Select
                                labelId="statusLabel"
                                id="status"
                                name="status"
                                label="status"
                                value={formValue["status"]}
                                onChange={handleChange}
                            >
                                <MenuItem name="status" value="0">Seleccione</MenuItem>
                                {procesos.map(
                                    (item)=>
                                    <MenuItem key={item.id} name="status" value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>

                    {/* <ReactFileReader
                        handleFiles = { handleFiles }
                        fileTypes = {'.csv'}
                        multipleFiles = { true }
                    >
                        <Button
                            variant = "contained"
                            component = "label"
                        >
                            Cargar CSV
                        </Button>
                    </ReactFileReader> */}

                    {/* <div
                        align = "center"
                    >
                        <input type = "file" onChange = { handleFiles } />
                        <CSVReader
                            ref = { buttonRef }
                            onFileLoad = { handleFiles }
                            onError = { onErrorHandler }
                            onClick
                            noDrag
                            onRemoveFile = { handleFileRemove }
                        >

                        </CSVReader>

                        <CSVReaderInput /> 
                    </div> */}
                </Paper>
            </Grid>
        </Grid>
    )
}