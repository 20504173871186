/* Importaciones */

// De React
import React, { useState , useEffect } from 'react';

// De MUI Material
import { 
    Button,
    Container,
    Typography,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Grid,
    Modal,
} from "@mui/material";

// Iconos
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
//import DeleteIcon from '@mui/icons-material/Delete';

// Modulos
import FormularioDirecciones from "./FormularioDirecciones";



/* Función principal */
export default function Index(props) {

    /* Variables */
    const [ open , setOpen ] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setId(null);
        obtenerDirecciones();
    };
    const [ id , setId ] = useState();
    const [ listaDirecciones , setListaDirecciones ] = useState([]);

    /* Conexión con la API */

    //Objeto para la configuracion de enviroment
    const envConfig = {
        url : (process.env.REACT_APP_ENVIROMENT === "prod") ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    };

    // Obtener direcciones
    const userData = JSON.parse( sessionStorage.getItem( 'user' ) );

    // Obtener direcciones
    const obtenerDirecciones = async () => {
        const url = envConfig.url + '/api/v1/adminDirecciones/get';

        const userID = {
            data : {
                user_id : userData.id
            }
        }

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify( userID )
        }).then((respuesta) => {
            if(!respuesta.ok) {
                alert(`Ocurrió un error: ${respuesta.status}`);
            }

            return respuesta.json();
        }).then(
            data => setListaDirecciones( data.data )
        ).catch(
            error => alert(`Ocurrió un error: ${error}`
        ));

    };

    useEffect( () => obtenerDirecciones() , [] );



    /* Retorna la vista */
    return (
        <Container maxWidth="lg">

            <Paper elevation={3} sx={ { marginY: 5, paddingX: 5, paddingY: 5} }>

                {/* Encabezado de la página */}
                <Grid container justifyContent="space-between" sx={{marginY: 2}}>
                    <Grid item>
                        <Typography variant='h5'>
                            Administra tus direcciones
                        </Typography>
                    </Grid>

                    <Grid item>
                            <Button variant="contained" onClick={ () =>{
                                setId(null);
                                handleOpen();
                            }}>Agregar dirección</Button>
                    </Grid>
                </Grid>

                {
                    (listaDirecciones.length === 0) ? (
                        `No hay direcciones registradas.`
                    ) : (
                        <TableContainer sx={{marginY: 2}}>
                            <Table>
                                {/* Encabezado de la tabla */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Domicilio</TableCell>
                                        <TableCell>Empresa</TableCell>
                                        <TableCell>Referencia</TableCell>
                                        <TableCell>Ubicación</TableCell>
                                    </TableRow>
                                </TableHead>

                                {/* Cuerpo de la tabla */}
                                <TableBody>
                                    {/* Se crea un map donde por cada dirección en direcciones cree una nueva fila */
                                        listaDirecciones.map((direccion) => (
                                            <TableRow key={direccion.id}>
                                                <TableCell sx={{"textAlign" : "center"}}>
                                                    {direccion.nombre} {direccion.apellidoPaterno} {direccion.apellidoMaterno}
                                                </TableCell>

                                                <TableCell>
                                                    {direccion.calle}
                                                    {/* Si el domicilio cuenta con número interior, se contena al número exterior, si no, solo se muestra el número exterior */
                                                        (direccion.numeroInterior) ? ` #${direccion.numeroExterior} - ${direccion.numeroInterior} \n` : ` #${direccion.numeroExterior} \n`
                                                    }

                                                    <br></br>
                                                    
                                                    {direccion.colonia}, {direccion.municipio} {direccion.codigoPostal}
                                                    
                                                    <br></br>
                                                    {direccion.estado}, {direccion.pais}
                                                </TableCell>

                                                <TableCell>
                                                    {direccion.empresa}
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {direccion.referencia}
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <IconButton href={'https://maps.google.com/maps/place/' + direccion.coordenadaLatitud + ',' + direccion.coordenadaLongitud} target="_blank">
                                                        <MapIcon />
                                                    </IconButton>
                                                </TableCell>

                                                {/* Botones de acción */}
                                                <TableCell>
                                                    <IconButton onClick={ () => {
                                                            setId(direccion.id);
                                                            handleOpen();
                                                        } }>
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>    
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                
                
            
            </Paper>

            <FormularioDirecciones open={open} handleClose={handleClose} id={id} />
            
        </Container>
    );
}