import React , { useState } from 'react';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

export default function DownloadButton(props){
    const {
        sx,
        waybill
    } = props;

    /* Estados */
    const [ waitAnResult , setWaitAnResult ] = useState(false);

    const envConfig = {
        url: (process.env.REACT_APP_ENVIROMENT==='prod') ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV 
    }

    /* Funcion para descargar la guia */
    const downloadingWaybill = async () => {
        setWaitAnResult(true);

        // const URL = 'https://system.logisticareyes.com.mx/api/v1/wayBills/getPDF';
        const URL = envConfig.url + '/api/v1/wayBills/getPDF';
        // const URL = `https://system.test.logisticareyes.com.mx/api/v1/wayBills/getPDF`;

        const newObjectJSON = {
            data : waybill
        };

        await fetch( URL , {
            method : "POST",
            headers : {
                "Content-Type": "application/json"
            },
            body : JSON.stringify( newObjectJSON )
        } )
            .then( res => res.blob() )
            .then( blob => {
                const download = require('downloadjs');
                download( blob , "Guia.pdf", "application/pdf");
            } )
            .catch( error => alert( 'Algo ocurrio :C ' + error ) );
            // .then( res => {
            //     if(!res.ok){
            //         alert(`Algo ocurio: ${ res.status }`);
            //     }
            //     return res.text();
            // } )
            // .then( data => alert(`La respuesta es: ${ JSON.stringify( data ) }`) )
            // .catch( error => alert( 'Algo ocurrio :C ' + error ) );
        setWaitAnResult(false);
    }

    /* Retornando el componente */
    return (
        <Button
            sx = { sx }
            variant = "contained"
            onClick = { downloadingWaybill }
            disabled = { waitAnResult }
        >
            <Download fontSize = "small"/>
            Descargar Guia
        </Button>
    );
}