import React, { useState } from 'react';
import {Grid,
        Container,
        Paper,
        Typography,
        CircularProgress,
        Box} from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import {Home,
        Send} from '@mui/icons-material';

import './shippingStyles.css';
import ButtonLink from './MarcoComponents/ButtonLink';
import CardInfo from './MarcoComponents/CardInfo';
// import CardService from './MarcoComponents/CardService';
import CardInsurance from './MarcoComponents/CardInsurance';
import TheMethodOfPaymentIs from './MarcoComponents/theMethodOfPaymentIs';
import { objectJSON } from './MarcoComponents/FormModel';

function LoadingCatchedInformation(props){
    const [ loading , setLoading ] = useState(false); 

    const service = {
        HowMuchCost : 750.60,
        DeliveryTime : 12,
        PackageSupplier : "Estafeta" 
    };

    const insuranceInfo = {
        subtotal : 1750.75,
        total : 2000.50,
        iva : 250.75
    };

    const cardInfoOrigin = {
        origenEmpresa : objectJSON.data.origin.origenEmpresa,
        origenPersona : `${ objectJSON.data.origin.origenPersona.nombre } ${ objectJSON.data.origin.origenPersona.apellidoPaterno } ${ objectJSON.data.origin.origenPersona.apellidoMaterno }`,
        origenCalle : objectJSON.data.origin.origenCalle,
        origenNumExt : objectJSON.data.origin.origenNumExt,
        origenNumInt : objectJSON.data.origin.origenNumInt,
        origenColonia : objectJSON.data.origin.origenColonia,
        origenMunicipio : objectJSON.data.origin.origenMunicipio,
        origenEstado : objectJSON.data.origin.origenEstado,
        origenPais : objectJSON.data.origin.origenPais,
        origenRef : objectJSON.data.origin.origenRef,
        origenCP : objectJSON.data.origin.origenCP,
        origenTelefono1 : objectJSON.data.origin.origenTelefono1,
        origenTelefono2 : objectJSON.data.origin.origenTelefono2
    };

    const cardInfoDestination = {
        destinoEmpresa : objectJSON.data.destination.destinoEmpresa,
        destinoPersona : `${ objectJSON.data.destination.destinoPersona.nombre } ${ objectJSON.data.destination.destinoPersona.apellidoPaterno } ${ objectJSON.data.destination.destinoPersona.apellidoMaterno }`,
        destinoCalle : objectJSON.data.destination.destinoCalle,
        destinoNumExt : objectJSON.data.destination.destinoNumExt,
        destinoNumInt : objectJSON.data.destination.destinoNumInt,
        destinoColonia : objectJSON.data.destination.destinoColonia,
        destinoMunicipio : objectJSON.data.destination.destinoMunicipio,
        destinoEstado : objectJSON.data.destination.destinoEstado,
        destinoPais : objectJSON.data.destination.destinoPais,
        destinoRef : objectJSON.data.destination.destinoRef,
        destinoCP : objectJSON.data.destination.destinoCP,
        destinoTelefono1 : objectJSON.data.destination.destinoTelefono1,
        destinoTelefono2 : objectJSON.data.destination.destinoTelefono2
    };

    const showingTheCirculeProgress = () => {
        setLoading( !loading );
    };

    return(
        // <div
        //     className = "containerGrid containerGrid--paymentConfirmation"
        // >
        //     <Grid
        //         container 
        //         spacing = { 2 }
        //     >

        //         <Grid
        //             item
        //             xs = { 6 }
        //         >
        //             <CardInfo 
        //                 icon = { <Home /> }
        //                 title = "Origen"
        //                 information = { cardInfoOrigin }
        //                 url = "/originOrDestination"
        //             />
        //         </Grid>

        //         <Grid
        //             item
        //             xs = { 6 }
        //         >
        //             <CardInfo 
        //                 icon = { <Send /> }
        //                 title = "Destino"
        //                 information = { cardInfoDestination }
        //                 url = "/originOrDestination"
        //             />
        //         </Grid>

        //         {/* <Grid
        //             item
        //             xs = { 12 }
        //         >
        //             <CardService 
        //                 url = "/shippingInfoList"
        //                 service = { service }
        //             />
        //         </Grid> */}

        //         <Grid
        //             item
        //             xs = { 12 }
        //         >
        //             <TheMethodOfPaymentIs />
        //         </Grid> 

        //         <Grid
        //             item
        //             xs = { 12 }
        //         >
        //             <CardInsurance 
        //                 data = { insuranceInfo }
        //             />
        //         </Grid>

        //     </Grid>
        // </div>
        <>
            <Container sx={{ marginBottom: 2 , textAlign: "center" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CardInfo 
                            icon = { <Home fontSize = "large"/> }
                            title = "Origen"
                            information = { cardInfoOrigin }
                            url = "/originOrDestination"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CardInfo 
                            icon = { <Send fontSize = "large"/> }
                            title = "Destino"
                            information = { cardInfoDestination }
                            url = "/originOrDestination"
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ marginBottom: 2 , textAlign: "center" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <TheMethodOfPaymentIs />
                    </Grid>

                    <Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center">
                        <CardInsurance 
                            data = { insuranceInfo }
                            showingTheCirculeProgress = { showingTheCirculeProgress }
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        {
                            ( loading ) ? <>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px"
                                    }}
                                >
                                    <Box>
                                        <CircularProgress 
                                            variant = "indeterminate"
                                            disableShrink
                                            size = {40}
                                            sx = {{
                                                animationDuration : "550ms",
                                                [`& .${circularProgressClasses.circle}`] : {
                                                    strokeLinecap : "round" 
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        variant = "body2"
                                        fontWeight = "bold"
                                    >
                                        Enviando información, espere un momento...
                                    </Typography>
                                </div>
                            </> : <></>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default function PaymentConfirmation(){

    return(
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ marginY: 5 , paddingX: 5 , paddingY: 5 }}>
                {/* Encabezado */}
                <Container sx={{ marginBottom: 2, textAlign: "left" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5">
                                Confirmación de pago y de datos
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <div align="right">
                                <ButtonLink 
                                    url = { objectJSON.data.isQuote ? "/originOrDestination" : "/methodsOfPayment"}
                                    clases = ""
                                    texto = "Regresar"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <LoadingCatchedInformation />

            </Paper>
        </Container>
    );
}

{/* <div className = "container container--shippingInfoList">
            
            <ButtonLink 
                url = "/methodsOfPayment"
                clases = "containerboton containerboton--paymentConfirmation"
                texto = "Regresar"
            />

            <LoadingCatchedInformation />

        </div> */}